import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {PlayPositionMarket} from "../sound/play_sound";
import {gameStore} from "../store";

let squareStore = [];

function GravitySquareWork(x, y, r) {

  let pos = GetGlobalPos(x, y, gameStore.map.id);
  if (!Scene.cameras.main.worldView.contains(pos.x, pos.y)) {
    return;
  }

  PlayPositionMarket("magnetic_field", 0, 0.128, pos.x, pos.y, 0.3)

  let shape1 = new Phaser.Geom.Circle(0, 0, r);

  let emitter
  if (squareStore.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      frame: 'white',
      speed: 40,
      lifespan: 2000,
      scale: {start: 0.5, end: 1},
      alpha: {start: 0.05, end: 0},
      blendMode: 'SCREEN',
      moveToX: {min: pos.x, max: pos.x},
      moveToY: {min: pos.y, max: pos.y},
      emitZone: {type: 'random', source: shape1},
      frequency: 48,
    });
    emitter.setDepth(400);
  } else {
    emitter = squareStore.shift();

    emitter.ops.moveToX.start = pos.x;
    emitter.ops.moveToX.end = pos.x;

    emitter.ops.moveToY.start = pos.y;
    emitter.ops.moveToY.end = pos.y;

    emitter.emitZones[0].source = shape1
  }

  emitter.explode(3, pos.x, pos.y);

  Scene.time.addEvent({
    delay: 2000,                // ms
    callback: function () {
      emitter.stop()
      squareStore.push(emitter);
    },
    loop: false
  });
}

export {GravitySquareWork}
