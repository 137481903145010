import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {Scene} from "../create";
import {SmokeExplosion} from "../weapon/explosion";
import {GetVolume, PlayPositionSound} from "../sound/play_sound";
import store from "../../store/store";

let beams = {};
let addBeams = {};
let laserStore = [];
let addLaserStore = [];

function FlyLaser(data, name, addkey = '') {
  if (!gameStore.gameReady) return;

  let laserName = name;
  if (!name && gameStore.gameTypes.ammo[data.type_id]) {
    laserName = gameStore.gameTypes.ammo[data.type_id].name;
  }

  if (!laserName) {
    console.trace("UNKNOWN LASER")
    return;
  }

  let key = data.unit_id + '' + data.et + '' + data.en

  if (laserName.includes("dismantling") || laserName.includes("dust_collector") || laserName === "mining") {
    additionalLaser(data, laserName, addkey, key)
  } else {
    defaultLaser(data, laserName, addkey, key)
  }
}

function defaultLaser(data, laserName, addkey = '', key) {
  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  let targetPos = GetGlobalPos(data.to_x, data.to_y, gameStore.map.id);
  if (!Scene.cameras.main.worldView.contains(pos.x, pos.y) && !Scene.cameras.main.worldView.contains(targetPos.x, targetPos.y)) {
    return;
  }

  key += addkey

  let color = ["blue"];
  let scale = 0.13;
  let quantity = 128;
  let beam = false
  let lifeSpan = 300
  let speed = {min: -5, max: 5}
  let alpha = {start: 1, end: 0}
  let updater = false

  if (laserName === "small_lens") {
    color = ['red'];
    scale = 0.09;
    lifeSpan = 256
  }
  if (laserName === "small_lens_beam") {
    color = ['green'];
    scale = 0.07;
    beam = true
  }
  if (laserName === "build") {
    color = ['yellow'];
    scale = 0.03;
  }
  if (laserName === "missile_defense") {
    color = ['red'];
    scale = 0.03;
    quantity = 32;
  }
  if (laserName === "heal") {
    color = ['green'];
    scale = 0.03;
    quantity = 32;
  }
  if (laserName === "transport_block") {
    color = ['red', 'blue'];
  }

  if (laserName === "mining") {
    color = ['yellow'];
    scale = 0.03;
    quantity = 32;
    beam = true
  }

  if (laserName === "super_small_blue") {
    color = ['blue'];
    scale = 0.03;
    quantity = 256;
    beam = true
  }

  if (laserName === "intro_1") {
    scale = 0.25;
    quantity = 64;
    speed = {min: -50, max: 50}
    alpha = {start: 0.05, end: 0}
  }

  if (laserName === "big_lens_1") {
    color = ['blue'];
    scale = 0.03;
    quantity = 256;
    beam = true
  }

  if (laserName === "medium_lens_beam") {
    beam = true
    updater = true

    scale = (data.accum_power / 1000) + 0.01;

    if (data.accum_power < 33) {
      color = ['red'];
    } else if (data.accum_power < 66) {
      color = ['yellow'];
    } else {
      color = ['blue'];
    }
  }

  if (!data.unit_id) key = pos.x + pos.y + addkey

  let update = function (emitter, line) {
    emitter.start();
    emitter.frequency = 0;

    emitter.setEmitterFrame(color);

    emitter.ops.scaleX.start = scale * 2;
    emitter.ops.scaleX.end = 0;

    emitter.emitZones[0].source = line

    emitter.ops.lifespan.current = lifeSpan
    emitter.ops.lifespan.propertyValue = lifeSpan

    emitter.ops.quantity.current = quantity / 4
    emitter.ops.lifespan.propertyValue = quantity / 4

    emitter.ops.speedX.start = speed.min;
    emitter.ops.speedX.end = speed.max;
    emitter.ops.speedX.propertyValue = speed;

    emitter.ops.alpha.start = alpha.start;
    emitter.ops.alpha.end = alpha.end;
    return emitter
  }

  if (updater && beams[key]) update(beams[key].emitter, beams[key].beam)

  if (!beam || !beams[key]) {

    let line = new Phaser.Geom.Line(pos.x, pos.y, targetPos.x, targetPos.y);
    let emitter

    if (laserStore.length === 0) {
      emitter = Scene.add.particles(0, 0, 'flares', {
        frame: color,
        x: 0,
        y: 0,
        scale: {start: scale * 2, end: 0},
        alpha: alpha,
        speed: speed,
        quantity: quantity / 4,
        emitZone: {source: line},
        blendMode: 'SCREEN',
        lifespan: lifeSpan,
      });
      emitter.setDepth(400);
    } else {
      emitter = update(laserStore.shift(), line)
    }

    if (!beam) {
      SmokeExplosion(targetPos.x, targetPos.y, 500, 15, 5, 10, null, null, 40);
      emitter.explode((quantity * 4) / 3);
      emitter.stop()

      Scene.time.addEvent({
        delay: lifeSpan,
        callback: function () {
          laserStore.push(emitter)
        },
        loop: false
      });
    } else {
      beams[key] = {
        beam: line,
        emitter: emitter,
        time_out: 128,
        sound: PlayPositionSound(['laser_buzz'], {
          loop: true,
          volume: 0
        }, pos.x, pos.y),
        time_out_checker: Scene.time.addEvent({
          delay: 32,                // ms
          callback: function () {

            if (!beams[key]) {
              return
            }

            beams[key].time_out -= 32
            if (beams[key].time_out < 0) {

              beams[key].time_out_checker.remove();

              if (beams[key].sound) {
                beams[key].sound.pause();
                beams[key].sound.destroy();
              }

              emitter.stop();
              laserStore.push(emitter)
              delete beams[key]
            }
          },
          loop: true
        })
      }
    }
  } else {
    if (beams.hasOwnProperty(key)) {
      beams[key].time_out = 128

      beams[key].beam.x1 = pos.x;
      beams[key].beam.y1 = pos.y;

      if (!store.getters.getSettings.MoveAndRotateTween) {
        Scene.tweens.add({
          targets: beams[key].beam,
          x2: targetPos.x,
          y2: targetPos.y,
          ease: 'Linear',
          duration: 64,
        });
      } else {
        beams[key].beam.x2 = targetPos.x;
        beams[key].beam.y2 = targetPos.y;
      }

      if (beams[key].sound) {
        let volume = GetVolume(pos.x, pos.y, 0.5);
        beams[key].sound.setVolume(volume);
      }
    }
  }
}

function additionalLaser(data, laserName, addkey = '', key) {

  let pos = GetGlobalPos(data.x, data.y, gameStore.map.id);
  let targetPos = GetGlobalPos(data.to_x, data.to_y, gameStore.map.id);
  if (!Scene.cameras.main.worldView.contains(pos.x, pos.y) && !Scene.cameras.main.worldView.contains(targetPos.x, targetPos.y)) {
    return;
  }

  key += addkey
  let color = ["blue"];
  let scale = 0.13;
  let quantity = 128;
  let beamTimeOut = 128
  let lifeSpan = 300
  let speed = {min: -5, max: 5}
  let alpha = {start: 1, end: 0}
  let updater = true
  let blendMode = 'SCREEN'
  let depth = 400
  let endScale = 0
  let emitZone = new Phaser.Geom.Line(pos.x, pos.y, targetPos.x, targetPos.y);
  let angle = null
  let moveToX = null
  let moveToY = null

  if (laserName === "dismantling") {
    color = ['white_05'];
    scale = 0.075;
    quantity = 64;
    lifeSpan = 1000;
    beamTimeOut = 64;
    moveToX = {min: pos.x, max: pos.x};
    moveToY = {min: pos.y, max: pos.y};
    alpha = {start: 0.06, end: 0.02};
    emitZone = new Phaser.Geom.Circle(targetPos.x, targetPos.y, 10);
    FlyLaser(data, 'dismantling_add', addkey = 'dismantling_add')
  }

  if (laserName === 'dismantling_add') {
    color = ['garbage_3', 'garbage_4', 'garbage_5'];
    scale = 0.1;
    quantity = 8;
    lifeSpan = 1000;
    beamTimeOut = 64;
    moveToX = {min: pos.x, max: pos.x};
    moveToY = {min: pos.y, max: pos.y};
    blendMode = undefined;
    angle = {min: getRandomArbitrary(0, 360), max: getRandomArbitrary(0, 360)}
    depth = 401
    endScale = 0.1;
    emitZone = new Phaser.Geom.Circle(targetPos.x, targetPos.y, 10);
  }

  if (laserName === "mining") {
    color = ['yellow'];
    scale = 0.06;
    endScale = 0.02;
    quantity = 32;
    lifeSpan = 1000;
    beamTimeOut = 64;
    moveToX = {min: pos.x, max: pos.x};
    moveToY = {min: pos.y, max: pos.y};
    alpha = {start: 0.1, end: 0.04};
    emitZone = new Phaser.Geom.Circle(targetPos.x, targetPos.y, 8);
    defaultLaser(data, laserName, key, addkey = 'mining_add')
  }

  if (laserName.includes("dust_collector")) {
    color = ['white_05'];
    alpha = {start: 0.1, end: 0.04};

    if (laserName === "dust_collector_8") {
      alpha = {start: 0.3, end: 0.04};
      color = ['yellow'];
    }
    if (laserName === "dust_collector_9") {
      alpha = {start: 0.3, end: 0.04};
      color = ['blue'];
    }
    if (laserName === "dust_collector_10") {
      alpha = {start: 0.3, end: 0.04};
      color = ['green'];
    }

    scale = 0.06;
    endScale = 0.02;
    quantity = 32;
    lifeSpan = 1000;
    beamTimeOut = 64;
    moveToX = {min: pos.x, max: pos.x};
    moveToY = {min: pos.y, max: pos.y};
    emitZone = new Phaser.Geom.Circle(targetPos.x, targetPos.y, 15);
  }

  if (!data.unit_id) key = pos.x + pos.y + addkey

  let update = function (emitter, emitZone) {
    emitter.start();
    emitter.frequency = 0;

    emitter.setEmitterFrame(color);

    emitter.ops.scaleX.start = scale * 2;
    emitter.ops.scaleX.end = 0;

    emitter.emitZones[0].source = emitZone

    emitter.ops.lifespan.current = lifeSpan
    emitter.ops.lifespan.propertyValue = lifeSpan

    emitter.ops.quantity.current = quantity / 4
    emitter.ops.lifespan.propertyValue = quantity / 4

    emitter.ops.speedX.start = speed.min;
    emitter.ops.speedX.end = speed.max;
    emitter.ops.speedX.propertyValue = speed;

    emitter.ops.alpha.start = alpha.start;
    emitter.ops.alpha.end = alpha.end;

    if (moveToX) {
      emitter.ops.moveToX.start = moveToX.min;
      emitter.ops.moveToX.end = moveToX.max;
    }

    if (moveToY) {
      emitter.ops.moveToY.start = moveToY.min;
      emitter.ops.moveToY.end = moveToY.max;
    }

    if (angle) {
      emitter.ops.angle.start = angle.min;
      emitter.ops.angle.end = angle.max;
    }

    emitter.setDepth(depth);
    emitter.setBlendMode(blendMode);
    return emitter
  }

  if (updater && addBeams[key]) update(addBeams[key].emitter, addBeams[key].beam)

  if (!addBeams[key]) {

    let emitter

    if (addLaserStore.length === 0) {
      let config = {
        frame: color,
        x: 0,
        y: 0,
        scale: {start: scale * 2, end: endScale},
        alpha: alpha,
        speed: speed,
        quantity: quantity / 4,
        emitZone: {source: emitZone},
        blendMode: blendMode,
        lifespan: lifeSpan,
        moveToX: moveToX,
        moveToY: moveToY,
        angle: angle,
      }

      emitter = Scene.add.particles(0, 0, 'flares', config);
      emitter.setDepth(depth);
    } else {
      emitter = update(addLaserStore.shift(), emitZone)
    }

    addBeams[key] = {
      beam: emitZone,
      emitter: emitter,
      time_out: beamTimeOut,
      sound: PlayPositionSound(['laser_buzz'], {
        loop: true,
        volume: 0
      }, pos.x, pos.y),
      time_out_checker: Scene.time.addEvent({
        delay: 32,                // ms
        callback: function () {

          if (!addBeams[key]) {
            return
          }

          addBeams[key].time_out -= 32
          if (addBeams[key].time_out < 0) {

            addBeams[key].time_out_checker.remove();

            if (addBeams[key].sound) {
              addBeams[key].sound.pause();
              addBeams[key].sound.destroy();
            }

            emitter.stop();
            addLaserStore.push(emitter)
            delete addBeams[key]
          }
        },
        loop: true
      })
    }
  } else {
    if (addBeams.hasOwnProperty(key)) {
      addBeams[key].time_out = beamTimeOut

      if (!store.getters.getSettings.MoveAndRotateTween) {
        Scene.tweens.add({
          targets: addBeams[key].beam,
          x: targetPos.x,
          y: targetPos.y,
          ease: 'Linear',
          duration: 64,
        });
      } else {
        addBeams[key].beam.x = targetPos.x;
        addBeams[key].beam.y = targetPos.y;
      }

      if (addBeams[key].sound) {
        let volume = GetVolume(pos.x, pos.y, 0.5);
        addBeams[key].sound.setVolume(volume);
      }
    }
  }
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

export {FlyLaser}
