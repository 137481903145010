import {gameStore} from "../store";
import {Scene} from "../create";
import {GetVolume, PlayPositionSound} from "../sound/play_sound";

let emitterAfterburnerStore = []

function Afterburner(id, w) {

  let unit = gameStore.units[id]
  if (!unit || !w) {
    if (unit && unit.afterburnerSound && unit.afterburnerSound.isPlaying) {
      if (unit.afterburnerSound.ticks > 15) {
        PlayPositionSound(['accelerate_stop'], null, unit.sprite.x, unit.sprite.y, false, 0.3);
      }

      unit.afterburnerSound.stop()
      unit.afterburnerSound.ticks = 0
    }
    return
  }

  if (!Scene.cameras.main.worldView.contains(unit.sprite.x, unit.sprite.y)) {
    return;
  }

  if (!unit.afterburnerSound) {
    unit.afterburnerSound = PlayPositionSound(['accelerate'], {
      loop: true,
      volume: GetVolume(unit.sprite.x, unit.sprite.y, 0.2)
    }, unit.sprite.x, unit.sprite.y);
  } else {
    if (!unit.afterburnerSound.isPlaying) unit.afterburnerSound.play()
    unit.afterburnerSound.setVolume(GetVolume(unit.sprite.x, unit.sprite.y, 0.2));
  }

  if (unit.afterburnerSound) {
    if (!unit.afterburnerSound.ticks) unit.afterburnerSound.ticks = 1
    unit.afterburnerSound.ticks++
  }

  let radian = Phaser.Math.DegToRad(unit.sprite.angle)
  let long = unit.sprite.displayHeight - 15;
  let p = {x: unit.sprite.x + long * Math.cos(radian), y: unit.sprite.y + long * Math.sin(radian)}
  let lifespan = 300

  let emitter
  if (emitterAfterburnerStore.length === 0) {
    emitter = Scene.add.particles(0, 0, 'flares', {
      frame: "white",
      gravityY: 0,
      quantity: 1,
      speed: {min: 75, max: 150},
      scale: {min: 0.4, max: 0.2},
      angle: {min: (unit.sprite.angle + 180) - 90, max: (unit.sprite.angle + 180) + 90},
      alpha: {start: 0.1, end: 0},
      lifespan: {min: lifespan / 2, max: lifespan},
      blendMode: 'ADD',
      x: p.x,
      y: p.y,
      frequency: 48,
    });
    emitter.setDepth(400);
  } else {
    emitter = emitterAfterburnerStore.shift();

    emitter.ops.angle.start = (unit.sprite.angle + 180) - 90;
    emitter.ops.angle.end = (unit.sprite.angle + 180) + 90;

    emitter.ops.x.current = p.x;
    emitter.ops.y.current = p.y;

    emitter.start();
  }

  Scene.time.addEvent({
    delay: lifespan,
    callback: function () {
      emitter.stop()
      emitterAfterburnerStore.push(emitter);
    },
  });
}

export {Afterburner}
