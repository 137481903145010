import {gameStore} from "../store";
import {statusBarWrapper} from "./status_bar";
import store from "../../store/store";
import {Scene, selectEquipSlot} from "../create";
import {ItemBinTypes} from "../../const";
import {GetItemByTypeAndID} from "../utils/get_item_by_type_and_id";

let equipBar = resetEquipBarState()
let initReloadTexture = false;
let innerCell = 2

function resetEquipBarState() {
  if (equipBar) {
    for (let i in equipBar.reloadTextures) {
      equipBar.reloadTextures[i].destroy();
    }

    for (let i in equipBar.noPowerTextures) {
      equipBar.noPowerTextures[i].destroy();
    }

    for (let i in equipBar.ammoCountTextures) {
      equipBar.ammoCountTextures[i].destroy();
    }

    for (let i in equipBar.activeEquipTextures) {
      equipBar.activeEquipTextures[i].destroy();
    }

    for (let i in equipBar.selectSprite) {
      equipBar.selectSprite[i].destroy();
    }

    if (equipBar.renderTexture) equipBar.renderTexture.destroy();
    if (equipBar.selectBorderSprite) equipBar.selectBorderSprite.destroy();
    if (equipBar.bracketL) equipBar.bracketL.destroy();
    if (equipBar.bracketR) equipBar.bracketR.destroy();
  }

  let cellSize = 44
  let position = {x: 400, y: -50}
  let bracketOffset = {x: 6, y: 47, scale: 0.20}
  if (false) { // TODO zoom > 10
    cellSize = 56
    position = {x: 400, y: -60}
    bracketOffset = {x: 8, y: 60, scale: 0.25}
  }

  equipBar = {
    slots: {},
    select: -1,
    cellSize: cellSize, // 44
    cellMargin: 2,
    renderTexture: null,
    bracketL: null,
    bracketR: null,
    renderTextureAmmo: null,
    position: position,
    reloadTextures: {},
    selectSprite: {},
    noPowerTextures: {},
    activeEquipTextures: {},
    ammoCountTextures: {},
    ammoCountCurrentTextures: {},
    selectBorderSprite: null,
    bracketOffset: bracketOffset
  }

  return equipBar
}

function initPanel() {
  if (!statusBarWrapper.scene || !gameStore.gameReady || equipBar.renderTexture || Object.keys(gameStore.equipPanel.slots).length === 0) return;

  let visibleCells = getMaxNumberCell()

  let panelBack = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});

  let height = equipBar.cellSize + (equipBar.cellMargin * 2);
  let width = ((visibleCells * (equipBar.cellSize + (equipBar.cellMargin * 2))) - (visibleCells * equipBar.cellMargin)) + (2 * equipBar.cellMargin);

  equipBar.position = {
    x: (statusBarWrapper.scene.cameras.main.displayWidth / 2) - (width / 2),
    y: statusBarWrapper.scene.cameras.main.displayHeight - (height + 40),
  }

  equipBar.bracketL = statusBarWrapper.scene.make.image({
    x: equipBar.position.x + equipBar.bracketOffset.x,
    y: equipBar.position.y + equipBar.bracketOffset.y,
    key: "equip_bar_bracket",
    add: true
  });
  equipBar.bracketL.setDepth(1200)
  equipBar.bracketL.setOrigin(0.5);
  equipBar.bracketL.setScale(equipBar.bracketOffset.scale);

  equipBar.bracketR = statusBarWrapper.scene.make.image({
    x: equipBar.position.x + width - equipBar.bracketOffset.x,
    y: equipBar.position.y + equipBar.bracketOffset.y,
    key: "equip_bar_bracket",
    add: true
  });
  equipBar.bracketR.setDepth(1200)
  equipBar.bracketR.setOrigin(0.5);
  equipBar.bracketR.setScale(equipBar.bracketOffset.scale);
  equipBar.bracketR.flipX = true;

  // shadow
  panelBack.fillStyle(0x000000, 0.4);
  panelBack.fillRoundedRect(0, 0, width, height + 2, 5);

  // back
  panelBack.fillStyle(0x25a0e1, 1);
  panelBack.fillRoundedRect(1, 1, width - 2, height, 5);

  // icons texture
  equipBar.renderTexture = statusBarWrapper.scene.add.renderTexture(equipBar.position.x, equipBar.position.y, width, height + 2);
  equipBar.renderTexture.setOrigin(0);
  equipBar.selectBorderSprite = statusBarWrapper.scene.make.image({
    x: equipBar.renderTexture.x,
    y: equipBar.renderTexture.y,
    key: "select_equip",
    add: true
  });
  equipBar.selectBorderSprite.setOrigin(0);
  equipBar.selectBorderSprite.setDisplaySize(
    equipBar.cellSize + (equipBar.cellMargin * 2) + 2,
    equipBar.cellSize + (equipBar.cellMargin * 2) + 2);
  equipBar.selectBorderSprite.setAlpha(0.75)

  createReloadTexture()

  for (let i = 0; i < visibleCells; i++) {
    let equipSlot = gameStore.equipPanel.slots[i + 1];
    if (!equipSlot) equipSlot = getEmptyCell([i + 1])

    let x = (i * equipBar.cellSize) + (equipBar.cellMargin * (i + 1)) + 1;
    let y = equipBar.cellMargin + 1;

    equipBar.slots[i + 1] = {x: x, y: y}
    panelBack.fillStyle(0x003c50);
    panelBack.fillRoundedRect(x, y, equipBar.cellSize, equipBar.cellSize, 5);

    equipBar.selectSprite[i + 1] = statusBarWrapper.scene.make.image({
      x: equipBar.renderTexture.x + x,
      y: equipBar.renderTexture.y + y + equipBar.cellSize,
      key: "selected_equip",
      add: true
    });
    equipBar.selectSprite[i + 1].setOrigin(0, 1);
    equipBar.selectSprite[i + 1].setAlpha(0.01);
    equipBar.selectSprite[i + 1].setInteractive();
    equipBar.selectSprite[i + 1].setDepth(1);
    equipBar.selectSprite[i + 1].on('pointerdown', function (pointer) {
      selectEquipSlot(i)
    })
    equipBar.selectSprite[i + 1].on('pointerover', function () {
      equipBar.selectSprite[i + 1].setAlpha(0.8);
      gameStore.HoldAttackMouse = true;
    });

    equipBar.selectSprite[i + 1].on('pointerout', function () {
      equipBar.selectSprite[i + 1].setAlpha(0.01);
      gameStore.HoldAttackMouse = false;
    });

    if (equipSlot.type_slot === 4) {
      panelBack.fillStyle(0x852700);
    } else if (equipSlot.type_slot === 1 || equipSlot.type_slot === 2 || equipSlot.type_slot === 3) {
      let slotItem = getItemInEquipPanel(equipSlot);
      if (slotItem.equip && slotItem.equip.active) {
        if (slotItem.equip.x_attach > 0 && slotItem.equip.y_attach > 0) {
          panelBack.fillStyle(0x0d8511);
        } else {
          panelBack.fillStyle(0x006485);
        }
      } else {
        panelBack.fillStyle(0x687585);
      }
    } else {
      panelBack.fillStyle(0x687585);
    }

    panelBack.fillRoundedRect(x + innerCell, y + innerCell, equipBar.cellSize - (innerCell * 2), equipBar.cellSize - (innerCell * 2), 5);

    if (equipSlot.type_slot === 255) {
      continue
    }

    if (equipSlot.type_slot > 10 && equipSlot.type_slot < 100) {
      panelBack.fillStyle(0x687585);
      continue
    }

    equipBar.reloadTextures[i + 1] = statusBarWrapper.scene.make.image({
      x: equipBar.renderTexture.x + x,
      y: equipBar.renderTexture.y + y + equipBar.cellSize,
      key: "equip_reload",
      add: true
    });
    equipBar.reloadTextures[i + 1].setOrigin(0, 1)

    if (equipSlot.type_slot === 1 || equipSlot.type_slot === 2 || equipSlot.type_slot === 3) {
      equipBar.noPowerTextures[i + 1] = statusBarWrapper.scene.make.image({
        x: equipBar.renderTexture.x + x, y: equipBar.renderTexture.y + y, key: "low_power", add: true
      });
      equipBar.noPowerTextures[i + 1].setDisplaySize(equipBar.cellSize, equipBar.cellSize)
      equipBar.noPowerTextures[i + 1].setOrigin(0)
    }
  }

  equipBar.renderTexture.draw(panelBack);
  panelBack.destroy();

  for (let i = 0; i < visibleCells; i++) {
    let equipSlot = gameStore.equipPanel.slots[i + 1];
    if (!equipSlot) equipSlot = getEmptyCell([i + 1])

    let x = (i * equipBar.cellSize) + (equipBar.cellMargin * (i + 1)) + 1;
    let y = equipBar.cellMargin + 1;

    let slotItem = getItemInEquipPanel(equipSlot);

    if (equipSlot.type_slot === 4 && slotItem && slotItem.weapon) {
      slotItem.item = slotItem.weapon
      slotItem.type = "weapon"
      if (!slotItem.item) continue;
    }

    if (equipSlot.type_slot === 1 || equipSlot.type_slot === 2 || equipSlot.type_slot === 3) {
      slotItem.item = slotItem.equip
      slotItem.type = "equip"
    }

    //if (equipSlot.type_slot > 10 && equipSlot.type_slot < 100) {
    //slotItem.item = slotItem.item
    //slotItem.type = slotItem.type
    //continue;
    //}

    if (equipSlot.type_slot === 255) {
      let icon = statusBarWrapper.scene.make.image({
        x: x + innerCell, y: y + innerCell, key: "empty_item", add: true
      });
      icon.setOrigin(0)
      icon.setDisplaySize(equipBar.cellSize - (innerCell * 2), equipBar.cellSize - (innerCell * 2))
      equipBar.renderTexture.draw(icon);
      icon.destroy();
      continue;
    }

    if (equipSlot.type_slot === -100) {
      continue;
    }

    if (!slotItem || !slotItem.item) {
      if (equipBar && equipBar.noPowerTextures[i + 1]) equipBar.noPowerTextures[i + 1].setAlpha(0.5)
      continue;
    }

    let key = 'equip_' + slotItem.type + slotItem.item.name

    Scene.load.image(key, getIconPath(slotItem));
    Scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
      let icon = statusBarWrapper.scene.make.image({
        x: x + innerCell, y: y + innerCell, key: key, add: true
      });
      icon.setOrigin(0)
      icon.setDisplaySize(equipBar.cellSize - (innerCell * 2), equipBar.cellSize - (innerCell * 2))
      equipBar.renderTexture.draw(icon);
      icon.destroy();

      let text = Scene.add.bitmapText(x + (innerCell * 2), y + (innerCell * 2), 'bit_text', getButtonByKey(i + 1), equipBar.cellSize / 4);
      text.setOrigin(0);
      text.setTint(0xFFFF00)
      equipBar.renderTexture.draw(text);
      text.destroy();
    })

    Scene.load.start()

    if (equipSlot.type_slot === 4) {
      loadAmmo(slotItem, x)
    }
  }

  getNewStateSlots()
}

function loadAmmo(slotItem, x) {
  if (!slotItem || !slotItem.ammo || slotItem.ammo.name === "") {
    return
  }

  let ammoX = x + innerCell;
  let ammoY = equipBar.cellSize / 2;
  let ammoWidth = (equipBar.cellSize - (innerCell * 2)) / 1.75;
  let ammoHeight = (equipBar.cellSize - (innerCell * 2)) / 1.75;

  let ammoIconBack = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});
  ammoIconBack.fillStyle(0xa5d5ef, 0.3);
  ammoIconBack.fillRoundedRect(ammoX, ammoY, ammoWidth, ammoHeight, 0);

  ammoIconBack.fillStyle(0x000000, 0.5);
  ammoIconBack.fillRoundedRect(ammoX + 1, ammoY + 1, ammoWidth - 2, ammoHeight - 2, 0);

  equipBar.renderTexture.draw(ammoIconBack);
  ammoIconBack.destroy();

  if (slotItem.ammo) {
    let ammoKey = 'ammo_' + slotItem.ammo.name
    Scene.load.image(ammoKey, getIconPath({
      type: "ammo", item: slotItem.ammo
    }));

    Scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
      let ammoIcon = statusBarWrapper.scene.make.image({
        x: ammoX + 1, y: ammoY + 1, key: ammoKey, add: true
      });
      ammoIcon.setOrigin(0)
      ammoIcon.setDisplaySize(ammoWidth - 2, ammoHeight - 2)
      equipBar.renderTexture.draw(ammoIcon);
      ammoIcon.destroy();
    })
    Scene.load.start()
  }
}

function createReloadTexture() {
  if (!initReloadTexture) {
    initReloadTexture = true
    let ammoIconBack = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});
    ammoIconBack.fillStyle(0x000000, 0.5);
    ammoIconBack.fillRoundedRect(0, 0, equipBar.cellSize, equipBar.cellSize, 5);
    ammoIconBack.generateTexture("equip_reload", equipBar.cellSize, equipBar.cellSize);
    ammoIconBack.destroy()

    let actionEquip = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});
    actionEquip.fillStyle(0xffffff, 0.05);
    actionEquip.fillRoundedRect(2, 2, equipBar.cellSize - 4, equipBar.cellSize - 4, 5);
    actionEquip.lineStyle(2, 0xff6600);
    actionEquip.strokeRoundedRect(2, 2, equipBar.cellSize - 3, equipBar.cellSize - 3, 5);
    actionEquip.generateTexture("equip_action", equipBar.cellSize, equipBar.cellSize);
    actionEquip.destroy()

    let selectEquip = statusBarWrapper.scene.add.graphics({x: 0, y: 0, add: true});
    selectEquip.fillStyle(0xffffff, 0.05);
    selectEquip.fillRoundedRect(2, 2, equipBar.cellSize - 4, equipBar.cellSize - 4, 5);
    selectEquip.lineStyle(2, 0xffffff);
    selectEquip.strokeRoundedRect(2, 2, equipBar.cellSize - 3, equipBar.cellSize - 3, 5);
    selectEquip.generateTexture("selected_equip", equipBar.cellSize, equipBar.cellSize);
    selectEquip.destroy()
  }
}

function getButtonByKey(key) {
  let number = Number(key)

  if (key === 2) {
    number += "|E"
  }

  if (key === 3) {
    number += "|F"
  }

  if (key === 4) {
    number += "|Q"
  }

  return number
}

function getIconPath(slot) {
  try {
    if (slot.type === "equip") {
      return require("../../assets/units/" + slot.type + "/icon/" + slot.item.name + ".png").default;
    }

    if (slot.type === "weapon") {
      return require("../../assets/units/weapon/icons/" + slot.item.name + ".png").default;
    }

    if (slot.type === "ammo") {
      return require("../../assets/units/" + slot.type + "/" + slot.item.name + ".png").default;
    }

    if (slot.type === "resource" || slot.type === "recycle") {
      return require("../../assets/resource/" + slot.item.name + ".png").default;
    }

    if (slot.type === "boxes") {
      return require("../../assets/" + slot.type + "/icons/" + slot.item.name + ".png").default;
    }

    if (slot.type === "detail") {
      return require("../../assets/resource/detail/" + slot.item.name + ".png").default;
    }

    if (slot.type === "blueprints") {
      return require("../../assets/blueprints/" + slot.item.icon + ".png").default;
    }

    if (slot.type === "body") {
      return require("../../assets/units/" + slot.type + "/icons/" + slot.item.name + ".png").default;
    }

    if (slot.type === "trash") {
      return require("../../assets/trashItems/" + slot.item.name + ".png").default;
    }

    if (slot.type === "structure") {
      return require("../../assets/trashItems/" + slot.item.name + ".png").default;
    }

    if (slot.type === "product") {
      return require(`../../assets/products/${slot.item.name}.png`).default;
    }

    if (slot.type === "fuel") {
      return require(`../../assets/fuel/${slot.item.name}.png`).default;
    }
  } catch (e) {
    console.log(e)
    return require(`../../assets/icons/question-mark.png`).default;
  }
}

function updateSlots(slots) {

  if (equipBar.select !== gameStore.equipPanel.select) {
    equipBar.select = gameStore.equipPanel.select;

    let selectSlot = slots[equipBar.select];
    if (selectSlot) {

      gameStore.equipPanel.selectApplicable = selectSlot.type;
      setCursor()

      Scene.tweens.add({
        targets: equipBar.selectBorderSprite,
        duration: 256,
        x: equipBar.position.x + equipBar.slots[equipBar.select].x - (equipBar.cellMargin + 1),
        y: equipBar.position.y + equipBar.slots[equipBar.select].y - (equipBar.cellMargin + 1),
      });
    }
  }

  for (let i in slots) {

    let newSlot = slots[i]

    if (equipBar.slots[i].reload !== newSlot.reload || newSlot.reloadReset) {
      if (newSlot.reload > 0) {

        if (equipBar.reloadTextures[i].tween && equipBar.reloadTextures[i].tween.isPlaying()) {
          equipBar.reloadTextures[i].tween.remove();
        }

        equipBar.reloadTextures[i].setDisplaySize(equipBar.cellSize, equipBar.cellSize);
        equipBar.reloadTextures[i].setVisible(true);

        equipBar.reloadTextures[i].tween = Scene.tweens.add({
          targets: equipBar.reloadTextures[i], props: {
            displayHeight: {value: 0, duration: newSlot.reload},
          },
        });
      } else if (equipBar.reloadTextures[i]) {
        equipBar.reloadTextures[i].setVisible(false)
        if (equipBar.reloadTextures[i].tween && equipBar.reloadTextures[i].tween.isPlaying()) {
          equipBar.reloadTextures[i].tween.remove();
        }
      }
    }

    if (newSlot.type_slot === 1 || newSlot.type_slot === 2 || newSlot.type_slot === 3) {
      if (equipBar.slots[i] && equipBar.slots[i].reload > 0 && newSlot.reload === 0) {
        if (!newSlot.no_sound) {
          store.dispatch('playSound', {
            sound: 'equipment_is_ready.mp3', k: 0.75,
          });
        }
      }

      if ((equipBar.slots[i].ammoCount !== newSlot.ammoCount || !equipBar.ammoCountCurrentTextures[i]) && newSlot.maxAmmoCount > 0) {
        let text = newSlot.ammoCount + '/' + newSlot.maxAmmoCount;
        if (!equipBar.ammoCountTextures[i + text]) {
          createText(text, newSlot, i)
        }

        if (equipBar.ammoCountCurrentTextures[i]) equipBar.ammoCountCurrentTextures[i].setVisible(false)
        equipBar.ammoCountCurrentTextures[i] = equipBar.ammoCountTextures[i + text]
        equipBar.ammoCountTextures[i + text].setVisible(true)
      }

      if (equipBar.slots[i].on !== newSlot.on) {
        if (newSlot.on) {
          if (!equipBar.activeEquipTextures[i]) {
            equipBar.activeEquipTextures[i] = statusBarWrapper.scene.make.image({
              x: equipBar.renderTexture.x + equipBar.slots[i].x,
              y: equipBar.renderTexture.y + equipBar.slots[i].y,
              key: "equip_action",
              add: true
            });
            equipBar.activeEquipTextures[i].setOrigin(0)
          }
          equipBar.activeEquipTextures[i].setVisible(true)
        } else {
          if (equipBar.activeEquipTextures[i]) equipBar.activeEquipTextures[i].setVisible(false);
        }
      }

      if (equipBar.slots[i].noPower !== newSlot.noPower) {
        if (newSlot.noPower) {
          if (equipBar.noPowerTextures[i]) equipBar.noPowerTextures[i].setVisible(true)
        } else {
          if (equipBar.noPowerTextures[i]) equipBar.noPowerTextures[i].setVisible(false)
        }
      }
    }

    if (newSlot.type_slot === 4) {
      if (equipBar.slots[i].reload !== newSlot.reload && newSlot.ammo_reload) {
        store.dispatch('playSound', {
          sound: 'weapon_reload.mp3', k: 0.5,
        });
      }

      if (equipBar.slots[i].ammo_reload && newSlot.reload === 0) {
        store.dispatch('playSound', {
          sound: 'weapon_loaded.mp3', k: 0.5,
        });
      }

      if (equipBar.slots[i].ammoQuantity !== newSlot.ammoQuantity) {

        if (!equipBar.ammoCountTextures[i + newSlot.ammoQuantity]) {
          createText(newSlot.ammoQuantity, newSlot, i)
        }

        if (equipBar.ammoCountCurrentTextures[i]) equipBar.ammoCountCurrentTextures[i].setVisible(false)
        equipBar.ammoCountCurrentTextures[i] = equipBar.ammoCountTextures[i + newSlot.ammoQuantity]
        equipBar.ammoCountTextures[i + newSlot.ammoQuantity].setVisible(true)
      }

      if (equipBar.slots[i].ammo_name !== newSlot.ammo_name) {
        loadAmmo({ammo: {name: newSlot.ammo_name}}, newSlot.x)
      }
    }

    if (newSlot.type_slot > 10 && newSlot.type_slot < 100) {
      if (!equipBar.ammoCountTextures[i + newSlot.quantity]) {
        createText(newSlot.quantity, newSlot, i)
      }

      if (equipBar.ammoCountCurrentTextures[i]) equipBar.ammoCountCurrentTextures[i].setVisible(false)
      equipBar.ammoCountCurrentTextures[i] = equipBar.ammoCountTextures[i + newSlot.quantity]
      equipBar.ammoCountTextures[i + newSlot.quantity].setVisible(true)
    }
  }

  equipBar.slots = slots;
}

function setCursor() {
  if (gameStore.equipPanel.selectApplicable === 'weapon') {
    document.body.classList.add("cursor_weapon");
  } else {
    document.body.classList.remove("cursor_weapon");
  }

  if (gameStore.equipPanel.selectApplicable === 'inventory_scanner') {
    document.body.classList.add("cursor_scanner");
  } else {
    document.body.classList.remove("cursor_scanner");
  }

  if (gameStore.equipPanel.selectApplicable === 'digger') {
    document.body.classList.add("cursor_digger");
  } else {
    document.body.classList.remove("cursor_digger");
  }
}

function UpdatePowerSlots() {
  for (let i in equipBar.slots) {
    let equipSlot = gameStore.equipPanel.slots[i];
    if (!equipSlot) continue;

    if (equipSlot.type_slot === 1 || equipSlot.type_slot === 2 || equipSlot.type_slot === 3) {
      if (noPower(equipSlot)) {
        if (equipBar.noPowerTextures[i]) equipBar.noPowerTextures[i].setVisible(true)
      } else {
        if (equipBar.noPowerTextures[i]) equipBar.noPowerTextures[i].setVisible(false)
      }
    }
  }
}

function createText(ammoText, newSlot, i) {

  let x = equipBar.renderTexture.x + newSlot.x + 4
  let y = equipBar.renderTexture.y + newSlot.y + (equipBar.cellSize / 1.5);

  let text = statusBarWrapper.scene.add.bitmapText(x, y, 'bit_text', ammoText, equipBar.cellSize / 3.5);
  text.setOrigin(0);
  text.setTint(0xFFFF00)

  equipBar.ammoCountTextures[i + ammoText] = text
}

function getNewStateSlots() {
  if (!statusBarWrapper.scene || !gameStore.gameReady || !equipBar.renderTexture || Object.keys(gameStore.equipPanel.slots).length === 0) {
    return;
  }

  let slots = {};

  let visibleCells = getMaxNumberCell()

  for (let i = 1; i <= visibleCells; i++) {

    let equipSlot = gameStore.equipPanel.slots[i];

    let slotItem = getItemInEquipPanel(equipSlot);
    if (!slotItem && equipBar.slots[i]) {
      slots[i] = {
        type: 'empty',
        x: equipBar.slots[i].x,
        y: equipBar.slots[i].y,
      };
      continue;
    }

    if (!equipBar.slots[i]) {
      // console.log(equipSlot)
      // console.log(equipBar.slots)
      continue
    }

    let reloadState = getReload(equipSlot, equipBar.slots[i])
    if ((equipSlot.type_slot === 1 || equipSlot.type_slot === 2 || equipSlot.type_slot === 3) && slotItem.equip) {
      slots[i] = {
        type_slot: equipSlot.type_slot,
        reload: reloadState.reload,
        maxAmmoCount: slotItem.equip.max_ammo_count,
        ammoCount: equipSlot.aq,
        on: equipSlot.on,
        noPower: noPower(equipSlot),
        x: equipBar.slots[i].x,
        y: equipBar.slots[i].y,
        no_sound: slotItem.equip.id === 8,
        type: slotItem.equip.applicable,
        sr: equipSlot.sr,
        er: equipSlot.er,
        reloadReset: reloadState.reset,
      }
      continue
    }

    if (equipSlot.type_slot === 4 && slotItem.weapon) {
      if (equipSlot.cr < 0) equipSlot.cr = 0;

      slots[i] = {
        ammo_name: '',
        type_slot: equipSlot.type_slot,
        reload: reloadState.reload,
        ammo_reload: equipSlot.am,
        reloadAmmoTime: slotItem.weapon.reload_ammo_time,
        ammoQuantity: equipSlot.aq,
        x: equipBar.slots[i].x,
        y: equipBar.slots[i].y,
        type: 'weapon',
      };

      if (slotItem.ammo) {
        slots[i].ammo_name = slotItem.ammo.name
      }
      continue
    }

    if (equipSlot.type_slot > 10 && equipSlot.type_slot < 100) {
      slots[i] = {
        quantity: slotItem.quantity,
        type_slot: equipSlot.type_slot,
        x: equipBar.slots[i].x,
        y: equipBar.slots[i].y
      }
      continue
    }

    console.log(equipSlot)
    console.log(equipBar.slots)
  }

  updateSlots(slots)
}

function noPower(equipSlot) {
  let slotItem = getItemInEquipPanel(equipSlot);
  if (!slotItem) {
    return false;
  }

  if (slotItem.type === 'equip') {
    if (location.href.includes('global')) {
      return gameStore.unitState.power < slotItem.item.use_power
    }

    if (location.href.includes('lobby')) {
      return false //getBody().state.max_power < slotItem.item.use_power
    }
  }

  if (equipSlot.type_slot === 1 || equipSlot.type_slot === 2 || equipSlot.type_slot === 3) {
    return gameStore.unitState.power < slotItem.equip.use_power
  }

  return false;
}

function getItemInEquipPanel(cell) {
  if (!cell) return;

  if (cell.source === "Constructor") {
    let {slot} = findEquip(cell.type_slot, cell.slot);
    return slot
  }

  if (cell.source === "squadInventory" || cell.source.includes('aInv:')) {

    let quantity = 0;

    let t = ItemBinTypes[cell.type_slot]
    let item = GetItemByTypeAndID(t, cell.slot)

    let inventory = store.getters.getInventory

    if (inventory.warehouses[cell.source] && inventory.warehouses[cell.source].inventory) {
      for (let i in inventory.warehouses[cell.source].inventory) {
        let slot = inventory.warehouses[cell.source].inventory[i];
        if (slot && slot.item && slot.type === t && slot.item_id === cell.slot) {
          quantity += slot.quantity;
        }
      }
    }

    if (!item) {
      return null;
    } else {
      return {type: t, quantity: quantity, item: item}
    }
  }

  return null
}

function findEquip(findType, findSlot) {
  let item = {slot: null};

  if (findType === 4) {
    for (let i in gameStore.unitState.weapon_slots) {
      let weapon = gameStore.unitState.weapon_slots[i];
      if (weapon.number_slot === findSlot) {
        item = {slot: weapon}
      }
    }
  }

  if (findType === 1 || findType === 2 || findType === 3) {
    for (let i in gameStore.unitState.equip_slots) {
      let equipSlot = gameStore.unitState.equip_slots[i];
      if (equipSlot.number_slot === findSlot && equipSlot.type_slot === findType) {
        item = {slot: equipSlot}
      }
    }
  }

  return item
}

function getReload(equipSlot, old) {
  let currentMS = Date.now()

  let reloadTime = equipSlot.er - equipSlot.sr;
  return {
    reload: reloadTime,
    current: equipSlot.er - currentMS,
    reset: old.er > 0 && old.sr > 0 && (old.er !== equipSlot.er || old.sr !== equipSlot.sr)
  }
}

function getEmptyCell(number) {
  return {
    number: number,
    source: "empty",
    type_slot: -100,
    slot: 0,
    sr: 0,
    er: 0,
    aq: 0,
    on: 0,
    mode: 0,
  }
}

function getMaxNumberCell() {
  let max = 0

  for (let i in gameStore.equipPanel.slots) {
    if (Number(i) > max) {
      max = Number(i)
    }
  }

  return max
}

export {
  initPanel,
  getNewStateSlots,
  resetEquipBarState,
  getIconPath,
  noPower,
  getButtonByKey,
  getMaxNumberCell,
  UpdatePowerSlots,
  setCursor
}
