import {Scene} from "../create";

function DrawDecals(unit) {
  for (let i in unit.decals) {
    let decal = unit.decals[i];
    if (!decal.draw) {
      drawDecal(unit, decal)
    }
  }
}

function AddDecal(unit, decal, draw) {
  if (!unit) return;

  if (!unit.decals) unit.decals = {};
  unit.decals[decal.x + ':' + decal.y] = decal
  if (draw) drawDecal(unit, decal);
}

function drawDecal(unit, decal) {
  let decalSprite = Scene.make.image({
    key: 'd' + decal.id, x: decal.x, y: decal.y, add: true
  });
  decalSprite.setOrigin(0.5);
  decalSprite.setAngle(decal.angle);
  decalSprite.setAlpha(0.7);

  unit.sprite.damage.draw(decalSprite);
  decalSprite.destroy();
  decal.draw = true;
}

export {DrawDecals, AddDecal}
