<template>
  <div style="pointer-events: auto;position: fixed;width: 100%;">
    <template
      v-if="currentMission && currentMission.id===258 && ((currentMission.currentAction >= 14 && currentMission.currentAction < 35) || currentMission.currentAction === -38)">
      <div class="training_helpers_2" v-if="!openDialog || !openDialog.page">
        <h3 class="training_helpers_head">{{ texts['text_1'][language] }}</h3>

        <div class="training_helpers_content"
             v-if="currentMission.currentAction >= 14 && currentMission.currentAction < 28">
          <div class="training_helpers_text" v-html="texts['text_2'][language]"/>

          <div class="training_helpers_text"><br>{{ texts['text_3'][language] }}</div>

          <div class="training_helpers_text">
            <ol style="margin-bottom: 8px;"
                v-if="currentPlayer.fraction === 'Replics' || currentPlayer.fraction === 'Reverses'">
              <li><span style="color: yellow">WASD</span> - {{ texts['text_4'][language] }}</li>
              <li><span style="color: yellow">L.Shift</span> - {{ texts['text_5'][language] }}</li>
              <li><span style="color: yellow">Space</span> - {{ texts['text_6'][language] }}</li>
              <li><span style="color: yellow">L</span> - {{ texts['text_20'][language] }}</li>
              <li><span style="color: yellow">Колесо мышки</span> - зум</li>
            </ol>

            <ol style="margin-bottom: 8px;" v-if="currentPlayer.fraction === 'Explores'">
              <li><span style="color: yellow">WASD</span> - {{ texts['text_4'][language] }}</li>
              <li><span style="color: yellow">L.Shift</span> - {{ texts['text_5'][language] }}</li>
              <li><span style="color: yellow">{{ texts['text_7'][language] }}</span> - {{ texts['text_8'][language] }}
              </li>
              <li><span style="color: yellow">L</span> - {{ texts['text_20'][language] }}</li>
              <li><span style="color: yellow">Колесо мышки</span> - зум</li>
            </ol>
          </div>

        </div>

        <div class="training_helpers_content" v-if="currentMission.currentAction === -38">
          <div class="training_helpers_text" v-html="texts['text_21'][language]"/>
          <br>
          <div class="training_helpers_img"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/help_select.gif').default + ')'}"></div>
          <br>
          <div class="training_helpers_text" v-html="texts['text_22'][language]"/>
        </div>

        <div class="training_helpers_content"
             v-if="currentMission.currentAction >= 28 && currentMission.currentAction < 35">

          <div class="training_helpers_text" v-html="texts['text_9'][language]"/>

          <div class="training_helpers_text"><br>{{ texts['text_10'][language] }}<br><br></div>

          <div class="training_helpers_text" v-html="texts['text_11'][language]"/>

          <div class="training_helpers_img"
               :style="{backgroundImage: 'url(' + require('../../assets/icons/select_weapon.png').default + ')'}"></div>

          <div class="training_helpers_text">
            <ol style="margin-bottom: 8px;">
              <li><span style="color: yellow">{{ texts['text_7'][language] }}</span> - {{ texts['text_12'][language] }}
              </li>
              <li><span style="color: yellow">{{ texts['text_13'][language] }}</span> - {{ texts['text_14'][language] }}
              </li>
              <li><span style="color: yellow">{{ texts['text_15'][language] }}</span> - {{ texts['text_16'][language] }}
              </li>
              <li><span style="color: yellow">R</span> - {{ texts['text_17'][language] }}</li>
            </ol>
          </div>

        </div>
      </div>
    </template>

    <div id="displayNotify">
      <div id="autoPilot" style="color: #04adf8" v-if="inventory.unit && inventory.unit.autoPilot">
        <div class="displayNotifyText">Autopilot</div>
      </div>

      <div id="catchAnomaly" style="color: #0cfdff" v-if="lowPower">
        <div class="displayNotifyText">Low energy!</div>
      </div>

      <div id="criticalDamage" style="color: red" v-if="damage">
        <div class="displayNotifyText">Damage!</div>
      </div>

      <div id="criticalDamage" style="color: red" v-if="criticalDamage">
        <div class="displayNotifyText">Critical damage!</div>
      </div>

      <div id="criticalDamage" style="color: red" v-if="glitch && glitch.x > 0 && glitch.y > 0">
        <div class="displayNotifyText">{{ texts['text_18'][language] }}</div>
      </div>

      <div id="FractionHostile" style="color: red"
           v-if="relationBonuses && relationBonuses[state.fraction] && relationBonuses[state.fraction].fraction_hostile">
        <div class="displayNotifyText">{{ texts['text_19'][language] }}</div>
      </div>
    </div>

    <div ref="connect_error" class="connect_error" v-if="nodeWSState && nodeWSState.error">
      <p>Связь с сервером потеряна.</p>
      <input type="button"
             :value="language === 'RU' ? 'Переподключить' : 'Reconnect'"
             @click="reconnect()" class="button cursor_hover">
    </div>
  </div>
</template>

<script>
import ObjectDialog from '../ObjectDialog/ObjectDialog'
import {gameStore} from "../../game/store";
import {CreateGame} from "../../game/create";
import {RemoveOldMap} from "../../game/map/remove_old_map";

export default {
  name: "Global",
  data() {
    return {
      sub: '',
    }
  },
  mounted() {

    this.$store.commit({
      type: 'setPlaceDialog',
      data: false,
    });

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "chat",
      data: {
        event: "OpenChat",
      }
    }));

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "inventory",
      data: {
        event: "OpenInventory",
      }
    }));

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "system",
      data: {
        event: "CheckInBase",
      }
    }));

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "market",
      data: {
        event: "GetCredits",
      }
    }));

    this.$store.dispatch("sendSocketData", JSON.stringify({
      service: "corporation",
      data: {
        event: "GetContacts",
      }
    }));

    CreateGame();
    gameStore.reload = false;
  },
  methods: {
    reconnect() {
      RemoveOldMap();
      gameStore.gameReady = false;
      gameStore.gameDataInit = {
        data: false,
        sendRequest: false,
      }
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
  },
  computed: {
    currentPlayer() {
      return this.$store.getters.getPlayer
    },
    openComponents() {
      return this.$store.getters.getNeedOpenComponents
    },
    criticalDamage() {
      return this.$store.getters.getCriticalDamage;
    },
    lowPower() {
      return this.$store.getters.getLowPower;
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    playersStatus() {
      return this.$store.getters.getSessionGamePlayersStatus
    },
    state() {
      return this.$store.getters.getSectorState
    },
    relationBonuses() {
      return this.$store.getters.getRelateBonuses
    },
    glitch() {
      return this.$store.getters.getGlitch
    },
    missions() {
      return this.$store.getters.getMissions;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Global')
    },
    damage() {
      return this.$store.getters.getDamage;
    },
    nodeWSState() {
      return this.$store.getters.getNodeWS;
    },
    currentMission() {
      if (this.missions && this.missions.missions && this.missions.missions[this.missions.currentMissionUUID] &&
        this.missions.missions[this.missions.currentMissionUUID][this.language] && this.missions.currentAction) {

        let m = this.missions.missions[this.missions.currentMissionUUID][this.language]
        let num = this.missions.currentAction.number;

        if (this.missions.currentAction.need_end && this.missions.currentAction.need_end.length > 0) {
          for (let a of this.missions.currentActions) {
            if (a.number === this.missions.currentAction.need_end[0] && !a.complete) {
              num = this.missions.currentAction.need_end[0]
            }
          }
        }

        return {id: m.id, currentAction: num}
      }
    },
    openDialog() {
      return this.$store.getters.getOpenDialog
    },
  },
  components: {}
}
</script>

<style scoped>
#displayNotify {
  position: fixed;
  width: 100%;
  pointer-events: none;
  top: 15%;
  left: 0;
}

#criticalDamage, #catchAnomaly, #dead, #autoPilot, #secureZone, #FractionHostile {
  width: 100%;
  text-align: center;
  height: 50px;
  color: #bdbd00;
  font-size: 50px;
  text-shadow: 0 -1px 1px #000000, 0 -1px 1px #000000, 0 1px 1px #000000, 0 1px 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px 0 1px #000000, 1px 0 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000, -1px -1px 1px #000000, 1px -1px 1px #000000, -1px 1px 1px #000000, 1px 1px 1px #000000;
}

#FractionHostile {
  font-size: 25px;
}

.displayNotifyText {
  animation: LowGravity 2s infinite;
}

@keyframes LowGravity {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.5;
  }
}

.respawnBlock {
  font-size: 16px;
  color: white;
  border-radius: 5px;
  padding: 3px 4px;
  width: 150px;
  margin: 6px auto;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  border: 1px solid rgba(37, 160, 225, 0.8);
  background: rgba(8, 138, 210, 0.8);
}

.respawnBlock.disable {
  opacity: 0.5;
}

.respawnButton {
  background: rgba(255, 129, 0, 0.75);
  box-shadow: 0 0 2px black;
  pointer-events: all;
  transition: 100ms;
  border-radius: 5px;
}

.respawnButton.disable {
  background: rgba(84, 78, 72, 0.75);
  color: rgba(145, 145, 145, 0.75);
}

.respawnButton:hover {
  background: rgba(255, 129, 0, 1);
}

.respawnButton.disable:hover {
  background: rgba(84, 78, 72, 0.75);
}

.respawnButton:active {
  transform: scale(0.98);
}

.respawnButton.disable:active {
  transform: scale(1);
}

.countRespawn {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 5px;
  font-size: 14px;
}

@media (max-width: 1000px) {
  .displayNotifyText {
    font-size: 25px !important;
  }
}

.training_helpers_2 {
  pointer-events: all;
  float: left;
  clear: both;
  width: 260px;
  top: 35px;
  left: calc(50% - 130px);
  position: absolute;
}

.training_helpers_2 {
  top: 3px;
  left: 3px;
  color: antiquewhite;
  background: rgba(0, 0, 0, 0.6);
  padding: 3px;
  border-radius: 5px;
  border: 1px solid rgba(128, 128, 128, 0.35);
  pointer-events: none;
  font-size: 12px;
  letter-spacing: 0.7px;
  font-weight: bold;
  backdrop-filter: blur(12px);
  user-select: none;
  min-height: 200px;
}

.training_helpers h3 {
  margin: 0 0 5px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 8px;
  line-height: 12px;
  height: 10px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  text-align: center;
}

.training_helpers_head {
  margin: 0 0 3px 0;
  background: rgb(221, 112, 52);
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  font-size: 13px;
  line-height: 17px;
  height: 16px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  box-shadow: 0 0 2px rgb(0 0 0);
  padding-left: 10px;
  text-align: left;
  clear: both;
}

.training_helpers_img {
  width: 132px;
  height: 84px;
  background-size: contain;
  margin-left: 55px;
  margin-bottom: -10px;
}

.connect_error {
  position: absolute;
  left: calc(50% - 140px);
  top: 65%;
  display: block;
  border-radius: 5px;
  width: 280px;
  padding: 3px;
  box-shadow: 0 0 2px black;
  font-size: 13px;
  text-align: left;
  background: rgba(8, 138, 210, 0.3);
  backdrop-filter: blur(4px);
  z-index: 1000;
  height: 34px;
  text-shadow: 1px 1px 1px black;
}

.connect_error p {
  float: left;
  color: wheat;
  width: 100%;
  margin: 0;
}

.button {
  display: block;
  width: 120px;
  pointer-events: auto;
  font-size: 13px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
  float: right;
  margin: 2px 2px 0 0;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}
</style>
