import store from "../../store/store";
import {Scene} from "../create";

let glitchState = {
  time_out: 0,
  checker: null,
}

function RunGlitch(power) {

  store.commit({
    type: 'setGlitch',
    f: power,
  });

  glitchState.time_out = 1000
  if (!glitchState.checker) {
    glitchState.checker = Scene.time.addEvent({
      delay: 128,                // ms
      callback: function () {
        if (glitchState.time_out < 0) {

          glitchState.checker.remove();
          glitchState.checker = null;

          store.commit({
            type: 'setGlitch',
            f: 0,
          });
        }

        glitchState.time_out -= 128;
      },
      loop: true
    })
  }
}

export {RunGlitch}
