<template>
  <div id="BuildTip"
       ref="BuildTip"
       :style="buildMenu.expandTip ? {left: 'calc(50% - 75px)', top: '20%', opacity: '1'} : mousePosition" v-if="true">

    <div class="error_message" :class="{error_message_visible: noPlaceSectorStructure}">
      <div style="margin-top: 14%">{{ texts['tip_fail'][language] }}</div>
    </div>

    <div class="wrapper" v-if="!buildMenu.expandTip" v-html="texts['tip_help'][language]">
    </div>

    <div class="wrapper" v-if="buildMenu.expandTip && blueprint">
      {{ texts['tip_request_1_1'][language] }}
      "<span class="importantly">{{ handbook['structure'][language][objectName].name }}</span>"
      {{ texts['tip_request_1_2'][language] }}
      <span class="importantly">{{ (blueprint.credits / 100).toFixed(2) }} cr.</span> ?
    </div>

    <div v-if="buildMenu.expandTip && blueprint">
      <input type="button" :value="texts['tip_button_1'][language]" class="button cursor_hover"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="close">
      <input type="button" :value="texts['tip_button_2'][language]" class="button cursor_hover"
             :class="{disable: mainDepositCredits < blueprint.credits}"
             @mouseover="playSound('select_sound.mp3', 0.3)"
             @click="place" style="float: right">
    </div>
  </div>
</template>

<script>
import {gameStore} from "../../game/store";
import {Scene} from "../../game/create";
import {MapSize} from "../../game/map/createMap";
import {placeStructure} from "../../game/build/select_place_build";

export default {
  name: "BuildTip",
  data() {
    return {
      mousePosition: {
        left: 0,
        top: 0,
        x: 0,
        y: 0,
      },
    }
  },
  mounted() {
    let app = this;
    $('body').mousemove(function (e) {
      app.updatePos(e);
    });
  },
  destroyed() {
    $('body').unbind('mousemove');
    this.$store.commit({
      type: 'setBuildExpandTip',
      expand: false
    });
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    updatePos(e) {
      this.mousePosition.left = (e.pageX + 100) + "px";
      this.mousePosition.top = (e.pageY + 100) + "px";

      if (gameStore.gameReady) {
        this.mousePosition.x = Math.round(Scene.input.mousePointer.worldX) - MapSize;
        this.mousePosition.y = Math.round(Scene.input.mousePointer.worldY) - MapSize;
      }
    },
    close() {
      this.$store.commit({
        type: 'setBuildExpandTip',
        expand: false,
      });
    },
    place() {
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "corporation",
        data: {
          event: "PlaceObject",
          x: Math.round(placeStructure.objectSprite.x - MapSize),
          y: Math.round(placeStructure.objectSprite.y - MapSize),
          rotate: Math.round(placeStructure.objectSprite.angle),
          id: this.buildMenu.selectBPID,
        }
      }));
    }
  },
  computed: {
    buildMenu() {
      return this.$store.getters.getBuildMenu
    },
    blueprint() {
      for (let i in this.buildMenu.points) {
        if (this.buildMenu.points[i].id === this.buildMenu.selectBPID) {
          return this.buildMenu.points[i]
        }
      }

      return false
    },
    objectName() {
      let bp = this.blueprint
      if (!bp) return '';
      bp = this.blueprint.blueprint

      // let user = this.$store.getters.getPlayer;
      // let fraction = "_" + user.fraction
      // if (bp.name.includes('corporation_base') || bp.name.includes('corporation_office') ||
      //   bp.name.includes('corporation_prefabricated') || bp.name.includes('corporation_processing')) {
      //
      //   fraction = ''
      // }
      let fraction = ''

      let name = bp.name.split('_bp')[0] + fraction;
      name = name.replace('mp_', '');
      return name.replace('map_', '');
    },
    corporation() {
      return this.$store.getters.getChatState.corporation;
    },
    mainDepositCredits() {
      if (this.corporation && this.corporation.deposits) {
        for (let i in this.corporation.deposits) {
          if (this.corporation.deposits[i].main) {
            return this.corporation.deposits[i].credits
          }
        }
      }

      return 0;
    },
    language() {
      return this.$store.getters.getSettings.Language
    },
    handbook() {
      return this.$store.getters.getHandBook
    },
    noPlaceSectorStructure() {
      let errors = this.$store.getters.getErrors
      if (errors.hasOwnProperty('no_place_sector_structure') && errors['no_place_sector_structure'].active) {

        setTimeout(function () {
          this.$store.commit({
            type: 'AddError',
            error: 'no_place_sector_structure',
            active: false,
          });
        }.bind(this), 750)

        return true
      }

      return false
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('BuildMenu')
    },
  }
}
</script>

<style scoped>
#BuildTip {
  line-height: 20px;
  min-width: 20px;
  opacity: 0.6;
  position: absolute;
  min-height: 45px;
  width: 150px;
  border: 1px solid rgba(37, 160, 225, 0.2);
  border-radius: 5px;
  z-index: 120;
  padding: 3px;
  filter: drop-shadow(0 0 2px black);
  background: rgba(8, 70, 140, 0.4);
  font-size: 12px;
  color: white;
  text-shadow: 1px 1px 1px black;
  font-weight: bold;
}

.wrapper {
  background: rgba(8, 138, 210, 0.5);
  border-radius: 3px;
  box-shadow: inset 0 0 2px black;
  padding: 5px;
}

.button {
  display: block;
  float: left;
  width: 45%;
  margin: 2px auto 0;
  pointer-events: auto;
  font-size: 11px;
  text-align: center;
  transition: 100ms;
  background: rgba(255, 129, 0, 0.6);
  height: 16px;
  border-radius: 5px;
  color: #fff;
  line-height: 15px;
  box-shadow: 0 0 2px #000;
}

.button:hover {
  background: rgba(255, 129, 0, 1);
}

.button:active {
  transform: scale(0.98);
}

.error_message {
  position: absolute;
  z-index: 20;
  text-align: center;
  width: 100%;
  height: 100%;
  font-weight: bold;
  background: rgba(0, 0, 0, 0.4);
  margin: 0;
  font-size: 24px;
  color: white;
  text-shadow: 1px 1px 1px black;
  transition: 750ms;
  opacity: 0;
  pointer-events: none;
  border-radius: 5px;
  top: 0;
  left: 0;
}

.error_message_visible {
  pointer-events: auto;
  opacity: 1;
}
</style>
