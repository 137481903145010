<template>
  <div class="MotherShipParamsWrapper">

    <app-state-tip v-if="tipState.param !== ''"
                   v-bind:title="tipState.title"
                   v-bind:param="tipState.param"
                   v-bind:x="tipState.x"
                   v-bind:y="tipState.y"
                   v-bind:wt="tipState.wt"
                   v-bind:ss="tipState.ss"
                   v-bind:bt="tipState.bt"
    />

    <div id="MotherShipParams">

      <template v-if="inventory.inBase && Object.keys(inventory.baseSquads).length > 0">
        <h3 class="SquadsListHead">
          {{ texts['text_17'][language] }}
          <div class="help_point_menu" @click.stop="openService('helpWrapper', {component: 'Hangar'})"></div>
        </h3>
        <div id="SquadsList">
          <div v-for="(squadName, index) in inventory.baseSquads"
               @mouseover="playSound('select_sound.mp3', 0.3)"
               @click="changeSquad(index)">
            <span class="squadListName">{{ squadName }}</span>
          </div>
        </div>
      </template>

      <div class="Value params cursor_hover" v-if="msParams.attack"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('attack')">▼
        {{ texts['ch_1'][language] }}
      </div>
      <div class="Value params cursor_hover" v-if="!msParams.attack"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('attack')">▶
        {{ texts['ch_1'][language] }}
      </div>

      <template v-if="inventory.stateMS && msParams.attack">
        <div class="infoParams" id="infoParamsAttack" v-if="inventory.stateMS.install">
          <table>
            <tr>
              <td class="head">{{ texts['ch_2'][language] }}:</td>
            </tr>
            <tr class="hover"
                @mousemove="showTip($event, texts['ch_2'][language], 'damage', inventory.stateMS.wt, inventory.stateMS.ws)"
                @mouseout="hideTip()">
              <td>
                {{ inventory.stateMS.weapon_min_damage }}
                -
                {{ inventory.stateMS.weapon_max_damage }}

                <span v-if="weapon && weapon.delay_following_fire === 0 && weapon.count_fire_bullet > 0"
                      style="font-size: 10px; color: lightgrey; text-shadow: none;"
                >x{{ weapon.count_fire_bullet }}</span>
              </td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_3'][language] }}:</td>
            </tr>

            <tr>
              <td colspan="2">
                <div class="type_damage_section" style="color: lightgrey">
                  <div class="type_damage_icon"
                       :style="{backgroundImage: 'url(' + require('../../assets/icons/damage_kinetics.png').default}"></div>
                  {{ inventory.stateMS.kinetics_damage + '%' }}
                </div>
                <div class="type_damage_section" style="color: rgb(255 156 2)">
                  <div class="type_damage_icon"
                       :style="{backgroundImage: 'url(' + require('../../assets/icons/damage_thermo.png').default}"></div>
                  {{ inventory.stateMS.thermo_damage + '%' }}
                </div>
                <div class="type_damage_section" style="border-right: 0; color: rgb(248, 255, 13)">
                  <div class="type_damage_icon"
                       :style="{backgroundImage: 'url(' + require('../../assets/icons/damage_explosion.png').default}"></div>
                  {{ inventory.stateMS.explosion_damage + '%' }}
                </div>
              </td>
            </tr>

            <tr>
              <td class="head">{{ texts['ch_4'][language] }}:</td>
            </tr>
            <tr>
              <td>{{ inventory.stateMS.count_fire_bullet }}</td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_5'][language] }}:</td>
            </tr>
            <tr class="hover">
              <td>{{ inventory.stateMS.weapon_min_range }} - {{ inventory.stateMS.weapon_max_range }}</td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_6'][language] }}:</td>
            </tr>
            <tr class="hover"
                @mousemove="showTip($event, texts['ch_6'][language], 'accuracy', inventory.stateMS.wt, inventory.stateMS.ws)"
                @mouseout="hideTip()">
              <td>{{ inventory.stateMS.weapon_accuracy }}</td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_7'][language] }}:</td>
            </tr>
            <tr class="hover"
                @mousemove="showTip($event, texts['ch_7'][language], 'gun_speed_rotate', inventory.stateMS.wt, inventory.stateMS.ws)"
                @mouseout="hideTip()">
              <td>{{ inventory.stateMS.gun_rotate_speed }}</td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_8'][language] }}:</td>
            </tr>
            <tr class="hover">
              <td>{{ inventory.stateMS.bullet_speed }}</td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_9'][language] }}:</td>
            </tr>
            <tr class="hover"
                @mousemove="showTip($event, texts['ch_9'][language], 'reload', inventory.stateMS.wt, inventory.stateMS.ws)"
                @mouseout="hideTip()">
              <!--              неверная формула, не учитывается делей пссле выструла и коунт булет-->
              <td>{{ Math.round(60000 / inventory.stateMS.reload_time) }} выс./мин</td>
            </tr>
            <tr>
              <td class="head">{{ texts['ch_10'][language] }}:</td>
            </tr>
            <tr class="hover"
                @mousemove="showTip($event, texts['ch_10'][language], 'reload_ammo', inventory.stateMS.wt, inventory.stateMS.ws)"
                @mouseout="hideTip()">
              <td>{{ inventory.stateMS.reload_ammo_time / 1000 }} сек.</td>
            </tr>
          </table>
        </div>

        <div class="infoParams" style="height: 15px" v-if="!inventory.stateMS.install">
          <table>
            <tr>
              <td class="head">{{ texts['text_18'][language] }}</td>
            </tr>
          </table>
        </div>
      </template>

      <div class="Value params cursor_hover" v-if="msParams.defend"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('defend')">▼
        {{ texts['ch_11'][language] }}
      </div>
      <div class="Value params cursor_hover" v-if="!msParams.defend"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('defend')">▶
        {{ texts['ch_11'][language] }}
      </div>

      <div class="infoParams" v-if="inventory.stateMS && msParams.defend"
           :style="{height: inventory.stateMS.max_shield_hp > 0 ? '122px':'104px'}">
        <table>
          <tr>
            <td class="head">{{ texts['ch_12'][language] }}</td>
          </tr>
          <tr>
            <td class="hover"
                @mousemove="showTip($event, texts['ch_12'][language], 'max_hp', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                @mouseout="hideTip()">
              {{ inventory.stateMS.max_hp }}
            </td>
          </tr>
          <tr>
            <td class="head">
              {{ texts['ch_13'][language] }}:
            </td>
          </tr>
          <tr>
            <td colspan="2">
              <div class="vul hover"
                   @mousemove="showTip($event, '', 'protect_kinetics', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                   @mouseout="hideTip()">
                <div :style="{background: '#666666', width: inventory.stateMS.protection_to_kinetics + '%' }"></div>
                <span>{{ inventory.stateMS.protection_to_kinetics }} %</span>
              </div>
              <div class="vul  hover"
                   @mousemove="showTip($event, '', 'protect_thermo', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                   @mouseout="hideTip()">
                <div :style="{background: '#de5011', width: inventory.stateMS.protection_to_thermo + '%' }"></div>
                <span>{{ inventory.stateMS.protection_to_thermo }} %</span>
              </div>
              <div class="vul  hover"
                   @mousemove="showTip($event, '', 'protect_explosion', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                   @mouseout="hideTip()">
                <div :style="{background: '#f8ff0d', width: inventory.stateMS.protection_to_explosion+'%' }"></div>
                <span>{{ inventory.stateMS.protection_to_explosion }} %</span>
              </div>
            </td>
          </tr>
          <tr>
            <td class="head">{{ texts['ch_25'][language] }}:</td>
          </tr>
          <tr>
            <td class="hover"
                @mousemove="showTip($event, texts['ch_25'][language], 'max_shield_hp', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                @mouseout="hideTip()">
              {{ inventory.stateMS.max_shield_hp > 0 ? inventory.stateMS.max_shield_hp : texts['ch_27'][language] }}
            </td>
          </tr>
          <tr v-if="inventory.stateMS.max_shield_hp > 0">
            <td colspan="2">
              <div class="vul hover"
                   @mousemove="showTip($event, '', 'shield_protect_kinetics', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                   @mouseout="hideTip()">
                <div
                  :style="{background: '#666666', width: inventory.stateMS.shield_protection_to_kinetics + '%' }"></div>
                <span>{{ inventory.stateMS.shield_protection_to_kinetics }} %</span>
              </div>
              <div class="vul  hover"
                   @mousemove="showTip($event, '', 'shield_protect_thermo', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                   @mouseout="hideTip()">
                <div
                  :style="{background: '#de5011', width: inventory.stateMS.shield_protection_to_thermo + '%' }"></div>
                <span>{{ inventory.stateMS.shield_protection_to_thermo }} %</span>
              </div>
              <div class="vul  hover"
                   @mousemove="showTip($event, '', 'shield_protect_explosion', '', inventory.stateMS.bs, inventory.stateMS.bt)"
                   @mouseout="hideTip()">
                <div
                  :style="{background: '#f8ff0d', width: inventory.stateMS.shield_protection_to_explosion+'%' }"></div>
                <span>{{ inventory.stateMS.shield_protection_to_explosion }} %</span>
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div class="Value params cursor_hover" v-if="msParams.nav"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('nav')">▼
        {{ texts['ch_14'][language] }}
      </div>
      <div class="Value params cursor_hover" v-if="!msParams.nav"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('nav')"> ▶
        {{ texts['ch_14'][language] }}
      </div>

      <div class="infoParams" v-if="inventory.stateMS && msParams.nav" style="height: 69px">
        <table>
          <tr>
            <td class="head">{{ texts['ch_15'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event, texts['ch_15'][language], 'view', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>{{ inventory.stateMS.range_view }}</td>
          </tr>
          <tr>
            <td class="head">{{ texts['ch_16'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event, texts['ch_16'][language], 'radar', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>{{ inventory.stateMS.range_radar }}</td>
          </tr>
        </table>
      </div>

      <div class="Value params cursor_hover" v-if="msParams.attack"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('power')">▼
        {{ texts['ch_17'][language] }}
      </div>
      <div class="Value params cursor_hover" v-if="!msParams.attack"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('power')">▶
        {{ texts['ch_17'][language] }}
      </div>

      <div class="infoParams" v-if="inventory.stateMS && msParams.power" style="height: 69px;">
        <table>
          <tr>
            <td class="head">{{ texts['ch_18'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event, texts['ch_18'][language], 'max_power', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>{{ inventory.stateMS.max_power }}</td>
          </tr>
          <tr>
            <td class="head">{{ texts['ch_19'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event, texts['ch_19'][language], 'charging_speed', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>

              {{
                Math.round(((1000 / inventory.stateMS.recovery_power_cycle) * inventory.stateMS.recovery_power) / 2)
              }}

              <span style="font-size: 10px; color: lightgrey; text-shadow: none">
                ({{ texts['ch_20'][language] }}: {{
                  Math.round((1000 / inventory.stateMS.recovery_power_cycle) * inventory.stateMS.recovery_power)
                }})</span>

              {{ texts['unitsec'][language] }}
            </td>
          </tr>
        </table>
      </div>

      <div class="Value params cursor_hover" v-if="msParams.move"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('move')">▼
        {{ texts['ch_21'][language] }}
      </div>
      <div class="Value params cursor_hover" v-if="!msParams.move"
           @mouseover="playSound('select_sound.mp3', 0.3)"
           @click="toggleMenu('move')">▶
        {{ texts['ch_21'][language] }}
      </div>

      <div class="infoParams" v-if="inventory.stateMS && msParams.move" style="height: 103px;">
        <table>
          <tr>
            <td class="head">{{ texts['ch_22'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event, texts['ch_22'][language], 'speed', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>{{ Math.round(inventory.unit.body.attributes.max_speed / 60) }} {{ texts['km'][language] }}</td>
          </tr>
          <tr>
            <td class="head">{{ texts['ch_23'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event,  texts['ch_23'][language], 'power_factor', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>+{{
                Math.round((inventory.unit.body.attributes.max_speed * ((1000 / inventory.unit.body.attributes.time_racing) * 100) / 100) / 60)
              }} {{ texts['kms'][language] }}
            </td>
          </tr>
          <tr>
            <td class="head">{{ texts['ch_24'][language] }}</td>
          </tr>
          <tr class="hover"
              @mousemove="showTip($event, texts['ch_24'][language], 'turn_speed', '', inventory.stateMS.bs, inventory.stateMS.bt)"
              @mouseout="hideTip()">
            <td>{{ Math.round(inventory.unit.body.attributes.turn_speed) }} {{ texts['deg'][language] }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import StateTip from './StateTip'
// inventory.unit.body
export default {
  name: "MSParams",
  data() {
    return {
      msParams: {
        attack: false,
        defend: false,
        nav: false,
        power: false,
        move: true,
      },
      tipState: {
        x: 0,
        y: 0,
        param: "",
        title: "",
        baseValue: 0,
        skillValue: 0,
        wt: "",
        ss: 0,
        bt: "",
      }
    }
  },
  methods: {
    playSound(sound, k) {
      if (sound === "button_press.mp3") k = 0.2
      if (sound === "select_sound.mp3") k = 0.1

      this.$store.dispatch('playSound', {
        sound: sound,
        k: k,
      });
    },
    openService(service, meta, component = '', forceOpen = false) {
      this.playSound('button_press.mp3', 0.3)
      this.sub = '';
      this.$store.commit({
        type: 'toggleWindow',
        id: service,
        component: component,
        meta: meta,
        forceOpen: forceOpen,
      });
    },
    toggleMenu(menu) {
      this.playSound('button_press.mp3', 0.3)
      this.msParams[menu] = !this.msParams[menu]
    },
    changeSquad(id) {
      this.playSound('button_press.mp3', 0.3)
      this.$store.dispatch("sendSocketData", JSON.stringify({
        service: "inventory",
        data: {
          event: "changeSquad",
          squad_id: Number(id),
        }
      }));
    },
    showTip(e, title, param, wt = '', ss = 0, bt = '') {
      let parent = this.$parent.$el;
      this.tipState.x = (e.pageX / this.interfaceZoom) - $(parent).position().left;
      this.tipState.y = (e.pageY / this.interfaceZoom) - $(parent).position().top;
      this.tipState.title = title
      this.tipState.param = param
      this.tipState.wt = wt
      this.tipState.ss = ss
      this.tipState.bt = bt
    },
    hideTip() {
      this.tipState.param = ""
    }
  },
  computed: {
    language() {
      return this.$store.getters.getSettings.Language
    },
    texts() {
      return this.$store.getters.getInterfaceHandBook('Hangar')
    },
    inventory() {
      return this.$store.getters.getInventory
    },
    interfaceZoom() {
      return this.$store.getters.getZoom
    },
    weapon() {
      if (this.inventory.unit && this.inventory.unit.weapon_slots) {
        for (let i in this.inventory.unit.weapon_slots) {
          let wSlot = this.inventory.unit.weapon_slots[i]
          if (wSlot.weapon) {
            return wSlot.weapon
          }
        }
      }

      return false
    },
  },
  components: {
    AppStateTip: StateTip,
  }
}
</script>

<style scoped>

.MotherShipParamsWrapper {
  float: left;
  height: calc(100% - 23px);
}

#MotherShipParams {
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
  display: inline-block;
  vertical-align: top;
  width: 215px;
  height: calc(100% - -1px);
  position: relative;
  float: left;
  margin-right: 6px;
  background-size: 100% 3px;
  background-image: linear-gradient(0deg, rgba(7, 41, 60, 0.6) 87%, rgba(0, 62, 95, 0.5) 30%);
  border: 1px solid rgba(0, 0, 0, 0.5);
  overflow-y: scroll;
  overflow-x: hidden;
}

#SquadsList {
  width: 201px;
  min-height: 20px;
  max-height: 100px;
  box-shadow: inset 0 0 2px black;
  border: 1px solid #4c4c4c;
  position: relative;
  margin-bottom: 4px;
  margin-left: 1px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgba(137, 150, 156, 0.3);
}

.Value.params {
  text-align: left;
  display: block;
  width: 190px;
  padding-left: 10px;
  background: linear-gradient(22deg, rgb(221, 112, 52), #6c6c6c);
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  margin: 2px 0 0 2px;
  font-size: 12px;
  height: 15px;
  user-select: none;
  -moz-user-select: none;
}

.infoParams {
  display: block;
  width: 195px;
  padding-left: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 1);
  margin: 2px 0 0 2px;
  background: rgba(183, 195, 201, 0.29);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 138px;
}

.infoParams table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  color: #c3c3c3;
  font-size: 12px;
}

.infoParams tr td {
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.infoParams tr td:last-child {
  color: white;
  text-shadow: 1px 1px 1px black;
  text-align: right;
  padding-right: 5px;
}

.Value.params:hover {
  box-shadow: 0 0 5px rgb(125, 125, 125);
}

.Value.params:active {
  box-shadow: 0 0 5px rgb(255, 255, 255);
}

.vul {
  width: calc(33% - 3px);
  height: 15px;
  float: left;
  background: rgba(122, 127, 122, 0.4);
  margin-left: 3px;
  box-shadow: inset 0 0 2px black;
  position: relative;
}

#infoParamsAttack {
  height: 307px;
}

.vul div {
  height: 100%;
  box-shadow: inset 0 0 2px 1px black;
}

.vul span {
  position: absolute;
  top: calc(50% - 6px);
  left: calc(50% - 6px);
  color: papayawhip;
  font-size: 11px;
  line-height: 16px;
  font-weight: bold;
  text-shadow: 1px 1px 1px black, 0 0 1px black, -1px -1px 1px black;
}

#SquadsList > div {
  transition: 0.1s;
  height: 16px;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  background: #5d5d5d57;
  padding-left: 10px;
  color: #ffe510;
  text-shadow: 1px 1px 1px black;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

#SquadsList > div:hover {
  background: rgba(235, 235, 235, 0.34);
  color: #ff7800;
}

#SquadsList > div:active {
  background: rgba(255, 255, 255, 0.73);
  color: #ff4208;
}

.up {
  color: #abff00;
}

.fine {
  color: red;
}

.infoParams tr span:hover {
  color: #05daff;
}

.SquadsListHead {
  margin: 0 0 0 0;
  background: #3486dd;
  color: rgba(255, 255, 255, 0.8);
  border-radius: 4px 4px 0 0;
  font-size: 12px;
  line-height: 14px;
  height: 13px;
  user-select: none;
  text-shadow: 1px 1px 1px rgb(0 0 0);
  font-weight: bold;
  padding-left: 10px;
  width: calc(100% - 7px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
}

.head {
  text-align: left !important;
  color: orange !important;
}

.hover:hover {
  background: rgba(0, 0, 0, 0.2);
}

.type_damage_section {
  width: calc(33% - 4px);
  height: 15px;
  float: left;
  margin-left: 3px;
  position: relative;
  line-height: 18px;
  text-align: left;
  border-right: 1px solid black;
  font-weight: bold;
}

.type_damage_icon {
  height: 15px;
  width: 15px;
  float: left;
  background: rgba(122, 127, 122, 0.4);
  box-shadow: inset 0 0 2px black;
  margin-right: 10px;
  margin-left: 2px;
}

.help_point_menu {
  height: 11px;
  width: 11px;
  position: absolute;
  right: 4px;
  bottom: 1px;
  background-image: url('../../assets/icons/question-mark.png');
  background-size: contain;
  border-radius: 50%;
  box-shadow: 0 0 2px black;
  opacity: 0.5;
}

.help_point_menu:hover {
  opacity: 1;
  box-shadow: 0 0 4px 2px orange;
}
</style>

<style>
.infoParams tr span:hover .tip {
  display: block;
}
</style>
