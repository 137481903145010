import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {Scene} from "../create";
import store from "../../store/store";
import {intFromBytes} from "../../utils";
import {ItemBinTypes} from "../../const";
import {GetItemByTypeAndID} from "../utils/get_item_by_type_and_id";
import {CreateReservoirStatus, RemoveReservoirStatus} from "../interface/reservoir_count";
import {GetMapLvl, GetSpriteSizeByMapLvl} from "../map/height_offset";
import {SetDayCycleMapItem} from "../day_cycle/day_cycle";

let cache = {};

function parseItem(data) {

  let map_item = {
    id: intFromBytes(data.slice(0, 4)),
    x: intFromBytes(data.slice(4, 8)),
    y: intFromBytes(data.slice(8, 12)),
    rotate: intFromBytes(data.slice(12, 16)),
    map_id: intFromBytes(data.slice(16, 20)),
    quantity: intFromBytes(data.slice(20, 24)),
    item_id: intFromBytes(data.slice(24, 28)),
    item_type: ItemBinTypes[intFromBytes(data.slice(28, 29))],
    radius: intFromBytes(data.slice(29, 30)),
  }

  map_item.item = GetItemByTypeAndID(map_item.item_type, map_item.item_id)
  return map_item
}

function CreateMapItems(data) {
  if (!gameStore.gameReady) return;

  data = parseItem(data);
  let oldItem = gameStore.mapItems[data.id];
  if (oldItem) return;

  let key = 'bullets'
  let frame = 'cargo'
  if (data.item_type === "resource") {
    key = "bullets"
    frame = data.item.name
  }

  if (data.item_type === "blueprints") {
    key = "items"
    frame = "blueprint"
  }

  let depth = 3 + GetMapLvl(data.x, data.y, gameStore.map.id)
  let pos = GetGlobalPos(data.x, data.y, data.map_id);
  data.x = pos.x;
  data.y = pos.y;

  data.frame = frame
  let item

  if (!cache[data.frame] || cache[data.frame].length === 0) {
    let shadow = Scene.make.sprite({
      x: data.x + 1,
      y: data.y + 1,
      key: key,
      frame: frame,
      add: true
    });

    shadow.setOrigin(0.5);

    shadow.setScale(GetSpriteSizeByMapLvl(1, 1 / 5, 0.05).x);
    shadow.setAngle(data.rotate);
    shadow.setAlpha(0.4);
    shadow.setTint(0x000000);
    shadow.setDepth(depth - 1);
    data.shadow = shadow;

    item = Scene.make.sprite({
      x: data.x,
      y: data.y,
      key: key,
      frame: frame,
      add: true
    });

    item.setOrigin(0.5);

    item.setScale(GetSpriteSizeByMapLvl(1, 1 / 5, 0.05).x);
    item.setAngle(data.rotate);
    item.setDepth(depth);
    data.sprite = item;

    data.light = Scene.add.pointlight(pos.x, pos.y, 0, 15, 0.75);
    data.light.attenuation = 0.03
    data.light.setDepth(depth - 2)
    data.light.color.setTo(159, 5, 255);

    data.lightTween = Scene.tweens.add({
      targets: data.light,
      props: {
        radius: {value: 20, duration: 1500},
      },
      repeat: -1,
      yoyo: true,
    });
  } else {
    let cacheObj = cache[data.frame].shift();
    data.sprite = cacheObj.sprite;
    data.shadow = cacheObj.shadow;
    data.light = cacheObj.light;

    data.sprite.setPosition(pos.x, pos.y);
    data.shadow.setPosition(pos.x + 1, pos.y + 1);
    data.light.setPosition(pos.x, pos.y);
    data.sprite.setAngle(data.rotate);
    data.shadow.setAngle(data.rotate);
    data.light.setAngle(data.rotate);

    data.sprite.setVisible(true);
    data.shadow.setVisible(true);
    data.light.setVisible(true);
    if (data.sprite.RadarMark) data.sprite.RadarMark.setVisible(true);

    item = data.sprite
  }

  item.setInteractive();
  //let postFxPlugin = Scene.plugins.get('rexoutlinepipelineplugin');
  item.on('pointerover', function (event) {

    if (gameStore.equipPanel.selectApplicable === 'empty')
      document.body.classList.add("cursor_hand");

    // postFxPlugin.add(item, {
    //   thickness: 1,
    //   outlineColor: 0xffffff
    // });

    if (data.item_type === "blueprints") {
      CreateReservoirStatus('item_' + data.id, data.item.item_type, data.item.item_name, item, data.quantity)
    } else {
      CreateReservoirStatus('item_' + data.id, data.item_type, data.item.name, item, data.quantity)
    }
  });

  item.on('pointerout', function (event) {
    if (gameStore.equipPanel.selectApplicable === 'empty')
      document.body.classList.remove("cursor_hand");

    //postFxPlugin.remove(item);
    RemoveReservoirStatus('item_' + data.id)
  });

  item.on('pointerdown', function (pointer, gameObject) {
    store.dispatch("sendSocketData", JSON.stringify({
      service: "global",
      data: {
        event: "get_map_item",
        id: Number(data.id),
      }
    }));
  });

  gameStore.mapItems[data.id] = data;
  SetDayCycleMapItem(gameStore.mapItems[data.id])
}

function DestroyItem(id) {
  RemoveReservoirStatus('item_' + id)

  let item = gameStore.mapItems[id];
  if (item) {
    if (!cache[item.frame]) {
      cache[item.frame] = []
    }

    let cacheObj = {
      sprite: item.sprite,
      shadow: item.shadow,
      light: item.light,
      mark: item.sprite.RadarMark,
    }

    item.sprite.setVisible(false);
    item.sprite.disableInteractive();
    item.shadow.setVisible(false);
    item.light.setVisible(false);

    if (item.sprite.RadarMark) item.sprite.RadarMark.setVisible(false);
    cache[item.frame].push(cacheObj)
    delete gameStore.mapItems[id]
  }
}

function ClearMapItemsCache() {
  for (let i in cache) {
    for (let j in cache[i]) {
      cache[i][j].sprite.destroy();
      cache[i][j].shadow.destroy();
      cache[i][j].light.destroy();
    }
  }

  cache = {}
}

export {CreateMapItems, DestroyItem, ClearMapItemsCache}
