import {intFromBytes} from "../../utils";
import {CreateReservoirStatus, RemoveReservoirStatus, UpdateReservoirStatus} from "../interface/reservoir_count";
import {gameStore} from "../store";
import {ClearBars} from "../interface/status_layer";
import {Scene} from "../create";
import {GetGlobalPos} from "../map/gep_global_pos";
import {SetDayCyclePollen} from "../day_cycle/day_cycle";
import {GetItemByTypeAndID} from "../utils/get_item_by_type_and_id";

let pollenStore = [];

function parse(data) {
  let pollen = {
    id: intFromBytes(data.slice(0, 4)),
    x: intFromBytes(data.slice(4, 8)),
    y: intFromBytes(data.slice(8, 12)),
    rotate: intFromBytes(data.slice(12, 16)),
    scale: intFromBytes(data.slice(16, 20)),
    height: intFromBytes(data.slice(20, 24)),
    count: intFromBytes(data.slice(24, 28)),
    complexity: intFromBytes(data.slice(28, 32)),
    resource_id: intFromBytes(data.slice(32, 33)),
    bufferMoveTick: [],
  }

  pollen.item = GetItemByTypeAndID('resource', pollen.resource_id)
  return pollen
}

let maxLifeTime = 10000;
let pollenMaxCount = 50 / 2
let frequency = 512

function CreatePollen(state) {
  let pollen = parse(state);

  let pos = GetGlobalPos(pollen.x, pollen.y, gameStore.map.id);
  let scale = (pollen.scale / 100)
  let count = (pollenMaxCount * scale) / 2
  let lifespan = maxLifeTime * scale

  pollen.mainShape = new Phaser.Geom.Circle(0, 0, scale * 128);
  pollen.shape = new Phaser.Geom.Circle(pos.x, pos.y, scale * 128);

  if (pollenStore.length === 0) {

    pollen.wortex = Scene.make.image({
      x: pos.x,
      y: pos.y,
      key: 'flares',
      frame: 'wortex',
      add: true
    });
    pollen.wortex.setDisplaySize(scale * 128, scale * 128)
    pollen.wortex.setOrigin(0.5);
    pollen.wortex.setAlpha(0.05);
    pollen.wortex.setDepth(901);
    pollen.wortex.tween = Scene.tweens.add({
      targets: pollen.wortex,
      props: {
        angle: {value: 360, duration: 3000},
      },
      repeat: -1,
    });

    pollen.sprite = Scene.add.particles(0, 0, 'flares', {
      frame: ['white_05', 'white_05', 'yellow'],
      x: 0,
      y: 0,
      lifespan: {min: lifespan / 2, max: lifespan},
      speed: {min: 0, max: 15, ease: 'Quad.easeIn'},
      gravityY: 0,
      scale: {start: 0, end: 1.5, ease: 'Quad.easeOut', easeParams: [30.5],},
      particleBringToTop: false,
      angle: {min: pollen.rotate - 5, max: pollen.rotate + 5},
      quantity: count,
      blendMode: 'SCREEN', // 'ADD', //'SCREEN'
      alpha: {start: 0.2, end: 0, ease: 'Quad.easeIn'},
      emitZone: {type: 'random', source: pollen.mainShape},
      frequency: frequency,
    });

    pollen.sprite.tween = Scene.tweens.add({
      targets: pollen.sprite,
      props: {
        angle: {value: 360, duration: 14000},
      },
      repeat: -1,
    });

    pollen.fairy = Scene.add.particles(0, 0, 'flares', {
      frame: ['white_05', 'white_05', 'yellow'],
      x: 0,
      y: 0,
      lifespan: {min: lifespan / 2, max: lifespan},
      speed: {min: 0, max: 15, ease: 'Quad.easeIn'},
      gravityY: 0,
      scale: {start: 0, end: 1.5, ease: 'Quad.easeOut', easeParams: [30.5],},
      particleBringToTop: false,
      angle: {min: pollen.rotate - 5, max: pollen.rotate + 5},
      quantity: count,
      blendMode: 'SCREEN', // 'ADD', //'SCREEN'
      alpha: {start: 0.2, end: 0, ease: 'Quad.easeIn'},
      emitZone: {type: 'random', source: pollen.shape},
      frequency: frequency,
    });

    pollen.circle = Scene.add.circle(pos.x, pos.y, scale * 128);
    pollen.circle.setAlpha(0.4)
    pollen.circle.setStrokeStyle(2, 0xffcc00);

    pollen.fairy.setDepth(900);
    pollen.sprite.setDepth(900);

  } else {
    let cache = pollenStore.shift()
    pollen.sprite = cache.sprite;

    pollen.sprite.ops.angle.start = pollen.rotate;
    pollen.sprite.ops.angle.end = pollen.rotate;

    pollen.sprite.ops.lifespan.start = lifespan / 2
    pollen.sprite.ops.lifespan.end = lifespan

    pollen.sprite.ops.quantity.current = count
    pollen.sprite.emitZones[0].source = pollen.mainShape

    pollen.sprite.start()

    pollen.fairy = cache.fairy;

    pollen.fairy.ops.angle.start = pollen.rotate;
    pollen.fairy.ops.angle.end = pollen.rotate;

    pollen.fairy.ops.lifespan.start = lifespan / 2
    pollen.fairy.ops.lifespan.end = lifespan

    pollen.fairy.ops.quantity.current = count
    pollen.fairy.emitZones[0].source = pollen.shape

    pollen.fairy.start()

    pollen.wortex = cache.wortex;
    pollen.wortex.setVisible(true);
    pollen.wortex.setPosition(pos.x, pos.y);
    pollen.wortex.setDisplaySize(scale * 128, scale * 128)

    pollen.circle = cache.circle;
    pollen.circle.setVisible(true);
    pollen.circle.setPosition(pos.x, pos.y);
    pollen.circle.radius = scale * 128

    if (pollen.sprite.RadarMark) pollen.sprite.RadarMark.setVisible(true);
  }

  pollen.sprite.flow(32, count * 2, 0);
  Scene.time.addEvent({
    delay: 64,
    loop: false,
    callback: function () {
      pollen.sprite.flow(frequency, count, 0);
      pollen.sprite.start();
    },
  });

  pollen.circle.setInteractive();
  pollen.circle.on('pointerover', function () {
    if (pollen.count > 0) CreateReservoirStatus('reservoir_' + pollen.id, 'resource', pollen.item.name, pollen.circle, pollen.count, 60)
  });

  pollen.circle.on('pointerout', function () {
    RemoveReservoirStatus('reservoir_' + pollen.id)
  });

  gameStore.pollens[pollen.id] = pollen;
  SetDayCyclePollen(pollen)
}

function UpdatePollen(id, updateMsg) {
  let p = gameStore.pollens[id];
  if (p) {
    p.count = intFromBytes(updateMsg.slice(0, 4))
    p.scale = intFromBytes(updateMsg.slice(4, 8))
    let scale = (p.scale / 100)

    if (p.fairy) {
      p.fairy.flow(frequency, pollenMaxCount * scale, 0);
      p.fairy.ops.lifespan.start = (maxLifeTime * scale) / 2
      p.fairy.ops.lifespan.end = (maxLifeTime * scale)
    }

    if (p.sprite) {
      p.sprite.flow(frequency, pollenMaxCount * scale, 0);
      p.sprite.ops.lifespan.start = (maxLifeTime * scale) / 2
      p.sprite.ops.lifespan.end = (maxLifeTime * scale)
    }

    if (p.wortex) {
      p.wortex.setDisplaySize(scale * 128, scale * 128)
    }

    p.shape.radius = scale * 128
    p.mainShape.radius = scale * 128

    Scene.tweens.add({
      targets: p.circle,
      duration: 128,
      radius: scale * 128,
    });

    UpdateReservoirStatus('reservoir_' + id, p.count, 60);
  }
}

function DestroyPollen(id) {
  let p = gameStore.pollens[id]
  if (!p) return;

  RemoveReservoirStatus('reservoir_' + id)
  ClearBars('reservoir', p.id, 'progress');

  if (p.sprite) {
    p.sprite.stop();
    p.fairy.stop();
    p.wortex.setVisible(false);
    p.circle.setVisible(false);
    p.circle.disableInteractive();
    if (p.sprite.RadarMark) p.sprite.RadarMark.setVisible(false);

    Scene.time.addEvent({
      delay: maxLifeTime,
      loop: false,
      callback: function () {
        pollenStore.push({
          sprite: p.sprite,
          fairy: p.fairy,
          wortex: p.wortex,
          circle: p.circle,
          RadarMark: p.RadarMark,
        });
      },
    });
  }

  delete gameStore.pollens[id];
}

export {CreatePollen, DestroyPollen, UpdatePollen}
