import {RedExplosion} from "../weapon/explosion";
import {gameStore} from "../store";
import {GetGlobalPos} from "../map/gep_global_pos";
import {FlyLaser} from "../bullet/laser";

function TransportBlock(id, x, y, z, r, toX, toY) {
  let pos = GetGlobalPos(x, y, gameStore.map.id);
  console.log(pos)

  RedExplosion(pos.x, pos.y, 400, 50, 30, 300, r - 5, r + 5, z - 10);

  let unit = gameStore.units[id];

  if (unit) {
    let data = {
      to_x: toX,
      to_y: toY,
      x: x,
      y: y,
    }

    FlyLaser(data, 'transport_block')
  }
}

export {TransportBlock}
