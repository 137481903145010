import {gameStore} from "../store";
import {Scene} from "../create";

let timeChange = 15000

let hoursTint = {
  // утро
  6: {
    o: {r: 170, g: 163, b: 102},
    s: 0.5,
    l: {in: {att: 0.03, r: 13}, out: {att: 0.1, r: 55, alp: 0.06, int: 0.3}}
  },
  7: {
    o: {r: 187, g: 179, b: 136},
    s: 0.6,
    l: {in: {att: 0.03, r: 11}, out: {att: 0.1, r: 55, alp: 0.05, int: 0.3}}
  },
  8: {
    o: {r: 204, g: 196, b: 170},
    s: 0.7,
    l: {in: {att: 0.03, r: 9}, out: {att: 0.1, r: 55, alp: 0.04, int: 0.3}}
  },
  9: {
    o: {r: 221, g: 213, b: 187},
    s: 0.8,
    l: {in: {att: 0.03, r: 7}, out: {att: 0.1, r: 55, alp: 0.03, int: 0.3}}
  },
  10: {
    o: {r: 238, g: 230, b: 221},
    s: 0.9,
    l: {in: {att: 0.03, r: 5}, out: {att: 0.1, r: 55, alp: 0.02, int: 0.3}}
  },
  // день
  11: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  12: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  13: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  14: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  15: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  16: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  17: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  18: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  19: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  20: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  21: {o: {r: 255, g: 255, b: 255}, s: 1, l: {in: {att: 0.04, r: 3}, out: {att: 0, r: 0, alp: 0, int: 0}}},
  // вечер
  22: {
    o: {r: 221, g: 221, b: 238},
    s: 0.9,
    l: {in: {att: 0.03, r: 5}, out: {att: 0.1, r: 55, alp: 0.02, int: 0.3}}
  },
  23: {
    o: {r: 187, g: 187, b: 221},
    s: 0.8,
    l: {in: {att: 0.03, r: 7}, out: {att: 0.1, r: 55, alp: 0.03, int: 0.3}}
  },
  0: {
    o: {r: 170, g: 170, b: 204},
    s: 0.7,
    l: {in: {att: 0.03, r: 9}, out: {att: 0.1, r: 55, alp: 0.04, int: 0.3}}
  },
  1: {
    o: {r: 136, g: 136, b: 187},
    s: 0.6,
    l: {in: {att: 0.03, r: 11}, out: {att: 0.1, r: 55, alp: 0.05, int: 0.3}}
  },
  2: {
    o: {r: 102, g: 102, b: 170},
    s: 0.5,
    l: {in: {att: 0.03, r: 13}, out: {att: 0.1, r: 55, alp: 0.06, int: 0.3}}
  },
  // ночь
  3: {o: {r: 68, g: 68, b: 136}, s: 0.4, l: {in: {att: 0.03, r: 15}, out: {att: 0.1, r: 55, alp: 0.08, int: 0.3}}},
  4: {o: {r: 68, g: 68, b: 136}, s: 0.4, l: {in: {att: 0.03, r: 15}, out: {att: 0.1, r: 55, alp: 0.08, int: 0.3}}},
  5: {o: {r: 68, g: 68, b: 136}, s: 0.4, l: {in: {att: 0.03, r: 15}, out: {att: 0.1, r: 55, alp: 0.08, int: 0.3}}},
}

// вызывает этот метод при обновлении времени суток
function SetDayCycle(force = true) {
  if (!gameStore.map || !gameStore.map.id || !gameStore.mapsState[gameStore.map.id] || !gameStore.mapsState[gameStore.map.id].bmdTerrain) {
    return;
  }

  // BACK
  let conf = hoursTint[gameStore.time.h]

  tweenTint(gameStore.mapsState[gameStore.map.id].bmdTerrain.bmd, conf.o, force)
  tweenTint(gameStore.mapsState[gameStore.map.id].bmdTerrain.bmdObject, conf.o, force)
  tweenTint(gameStore.mapsState[gameStore.map.id].bmdTerrain.bmdWater, conf.o, force)

  // UNITS
  for (let i in gameStore.units) {
    SetDayCycleUnit(gameStore.units[i], force)
  }

  // DRONES
  for (let i in gameStore.drones) {
    SetDayCycleDrone(gameStore.drones[i], force)
  }

  // OBJECTS
  for (let i in gameStore.objects) {
    SetDayCycleObject(gameStore.objects[i], force)
  }

  for (let obj of gameStore.mapsState[gameStore.map.id].staticObjects) {
    SetDayCycleObject(obj, force)
  }

  // BOXES
  for (let i in gameStore.boxes) {
    SetDayCycleBox(gameStore.boxes[i], force)
  }

  // ITEMS
  for (let i in gameStore.mapItems) {
    SetDayCycleMapItem(gameStore.mapItems[i], force)
  }

  // RESERVOIRS
  for (let i in gameStore.reservoirs) {
    SetDayCycleReservoirs(gameStore.reservoirs[i], force)
  }

  // BULLETS
  for (let i in gameStore.bullets) {
    SetDayCycleBullet(gameStore.bullets[i], force)
  }

  // BULLETS
  for (let i in gameStore.pollens) {
    SetDayCyclePollen(gameStore.pollens[i], force)
  }
}

function SetDayCycleUnit(unit, force = true) {
  if (unit && unit.sprite) {

    let conf = hoursTint[gameStore.time.h]

    if (unit.sprite.bodyBottom) tweenTint(unit.sprite.bodyBottom, conf.o, force);
    if (unit.sprite.bodyBottomRight) tweenTint(unit.sprite.bodyBottomRight, conf.o, force);
    if (unit.sprite.bodyBottomLeft) tweenTint(unit.sprite.bodyBottomLeft, conf.o, force);
    if (unit.sprite) tweenTint(unit.sprite, conf.o, force);

    for (let i in unit.weapons) {
      if (unit.weapons[i].weapon) {
        tweenTint(unit.weapons[i].weapon, conf.o, force);
      }
    }

    for (let e of unit.equips) {
      if (e.sprite) tweenTint(e.sprite, conf.o, force);
    }

    if (unit.sprite.userName) {
      if (unit.sprite.userName.login) unit.sprite.userName.login.setAlpha(conf.s)
      if (unit.sprite.userName.pointer) unit.sprite.userName.pointer.setAlpha(conf.s)
      if (unit.sprite.userName.attitude) unit.sprite.userName.attitude.setAlpha(conf.s)
      if (unit.sprite.userName.detail) unit.sprite.userName.detail.setAlpha(conf.s)
      if (unit.sprite.userName.pk) unit.sprite.userName.pk.setAlpha(conf.s)
    }

    if (unit.light) {
      for (let i in unit.lights) {
        if (i.includes('out') || i.includes('out')) {
          if (force) {
            unit.lights[i].attenuation = conf.l.out.att
            unit.lights[i].radius = conf.l.out.r
            unit.lights[i].alpha = conf.l.out.alp
            unit.lights[i].intensity = conf.l.out.int
          } else {
            Scene.tweens.add({
              targets: unit.lights[i],
              duration: timeChange,
              attenuation: conf.l.out.att,
              radius: conf.l.out.r,
              alpha: conf.l.out.alp,
              intensity: conf.l.out.int,
            });
          }
        } else {
          if (force) {
            unit.lights[i].attenuation = conf.l.in.att
            unit.lights[i].radius = conf.l.in.r
          } else {
            Scene.tweens.add({
              targets: unit.lights[i],
              duration: timeChange,
              attenuation: conf.l.in.att,
              radius: conf.l.in.r,
            });
          }
        }
      }
    }
  }
}

function SetDayCycleDrone(d, force = true) {
  if (d && d.sprite) {
    let conf = hoursTint[gameStore.time.h]
    tweenTint(d.sprite, conf.o, force)
  }
}

function SetDayCycleObject(obj, force = true) {
  if (obj && obj.objectSprite && !obj.disable) {

    let conf = hoursTint[gameStore.time.h]

    if (obj.objectSprite.weapon) tweenTint(obj.objectSprite.weapon, conf.o, force);
    if (obj.objectSprite.weaponBox) tweenTint(obj.objectSprite.weaponBox, conf.o, force);
    if (obj.objectSprite.equip) tweenTint(obj.objectSprite.equip, conf.o, force);
    if (obj.objectSprite.equipBox) tweenTint(obj.objectSprite.equipBox, conf.o, force);
    if (obj.objectSprite.top) tweenTint(obj.objectSprite.top, conf.o, force);
    if (obj.objectSprite.top2) tweenTint(obj.objectSprite.top2, conf.o, force);

    tweenTint(obj.objectSprite, conf.o, force)
  }
}

function SetDayCycleBox(box, force = true) {
  if (box && box.sprite) {
    let conf = hoursTint[gameStore.time.h]
    tweenTint(box.sprite, conf.o, force)
  }
}

function SetDayCycleMapItem(item, force = true) {
  if (item && item.sprite) {
    let conf = hoursTint[gameStore.time.h]
    tweenTint(item.sprite, conf.o, force)
  }
}

function SetDayCycleReservoirs(reservoir, force = true) {
  if (reservoir && reservoir.sprite) {
    let conf = hoursTint[gameStore.time.h]
    tweenTint(reservoir.sprite, conf.o, force)
  }
}

function SetDayCycleBullet(bullet, force = true) {
  if (bullet && bullet.sprite && bullet.type_bullet !== "meteorite") {
    let conf = hoursTint[gameStore.time.h]
    tweenTint(bullet.sprite, conf.o, force)
  }
}

function SetDayCyclePollen(p) {
  if (p && p.sprite) {
    let conf = hoursTint[gameStore.time.h]
    p.sprite.particleTint = Phaser.Display.Color.GetColor(conf.o.r, conf.o.g, conf.o.b);
    p.fairy.particleTint = Phaser.Display.Color.GetColor(conf.o.r, conf.o.g, conf.o.b);
  }
}

function tweenTint(target, conf, force = true) {

  if (force) {
    target.setTint(Phaser.Display.Color.GetColor(conf.r, conf.g, conf.b));
    return
  }

  if (target.DayNightTween) {
    target.DayNightTween.destroy();
  }

  let counter = 0
  target.DayNightTween = Scene.tweens.addCounter({
    from: 0,
    to: 256,
    duration: timeChange,
    onUpdate: function (tween) {
      let value = Math.floor(tween.getValue());
      if (value === counter) {
        return;
      }

      counter = value
      let color = Phaser.Display.Color.HexStringToColor('0x' + target.tint.toString(16));

      if (color.r === conf.r && color.g === conf.g && color.b === conf.b) {
        tween.remove();
        return
      }

      if (color.r < conf.r) color.r++;
      if (color.g < conf.g) color.g++;
      if (color.b < conf.b) color.b++;
      if (color.r > conf.r) color.r--;
      if (color.g > conf.g) color.g--;
      if (color.b > conf.b) color.b--;

      target.setTint(Phaser.Display.Color.GetColor(color.r, color.g, color.b));
    }
  });
}

export {
  SetDayCycle,
  SetDayCycleUnit,
  SetDayCycleDrone,
  SetDayCycleObject,
  SetDayCycleBox,
  SetDayCycleMapItem,
  SetDayCycleReservoirs,
  SetDayCycleBullet,
  SetDayCyclePollen,
  hoursTint
}
