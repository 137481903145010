import {Scene} from "../create";
import {getIconPath} from "./equip_bar";
import {gameStore} from "../store";
import {getBarScale} from "./status_layer";

let reservoirStatus = {
  texture: null,
  height: 28 * 2,
  width: 70 * 2,
  data: {
    uuid: null,
    type: null,
    name: null,
    count: null,
    parentSprite: null,
  }
}

let loadCache = {}

function CreateReservoirStatus(uuid, type, name, parentSprite, count, offsetY = 20) {

  if (!gameStore.gameReady) return;

  if (reservoirStatus.texture) RemoveReservoirStatus('all');

  reservoirStatus.data = {
    uuid: uuid,
    type: type,
    name: name,
    count: count,
    parentSprite: parentSprite,
  };

  reservoirStatus.texture = Scene.add.renderTexture(parentSprite.x, parentSprite.y - offsetY, reservoirStatus.width, reservoirStatus.height + 2);
  reservoirStatus.texture.setDepth(1999)
  reservoirStatus.texture.setScale(0.4 * getBarScale())

  let back = Scene.add.graphics({x: 0, y: 0, add: true});

  // shadow
  back.fillStyle(0x000000, 0.4);
  back.fillRoundedRect(0, 0, reservoirStatus.width, reservoirStatus.height, 3);

  // back
  back.fillStyle(0x25a0e1, 0.3);
  back.fillRoundedRect(1, 1, reservoirStatus.width - 2, reservoirStatus.height - 2, 3);
  reservoirStatus.texture.draw(back);
  back.destroy();

  //'#4eb6ec'
  // icon back
  back = Scene.add.graphics({x: 0, y: 0, add: true});
  back.fillStyle(0x4eb6ec, 0.6);
  back.fillRoundedRect(2, 2, reservoirStatus.height - 4, reservoirStatus.height - 4, 3);
  reservoirStatus.texture.draw(back);
  back.destroy();

  let key = type + '_' + name

  if (loadCache[key]) {
    createSprite(key, count)
  } else {
    Scene.load.image(key, getIconPath({type: type, item: {name: name}}))
    Scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
      loadCache[key] = true
      createSprite(key, count)
    })

    Scene.load.start()
  }
}

function createSprite(key, count) {
  if (!reservoirStatus.texture) return;

  let shadow = Scene.make.image({
    x: 5, y: 5, key: key, add: true
  });
  shadow.setTint(0x000000)
  shadow.setOrigin(0)
  shadow.setDisplaySize(reservoirStatus.height - 8, reservoirStatus.height - 8)
  shadow.setAlpha(0.5)
  reservoirStatus.texture.draw(shadow);
  shadow.destroy();

  let icon = Scene.make.image({
    x: 4, y: 4, key: key, add: true
  });
  icon.setOrigin(0)
  icon.setDisplaySize(reservoirStatus.height - 8, reservoirStatus.height - 8)
  reservoirStatus.texture.draw(icon);
  icon.destroy();

  let text = Scene.add.bitmapText(65 * 2, 24 * 2, 'bit_text', count, 34);
  text.setOrigin(1);
  text.setLetterSpacing(1)
  text.setTint(0xFFFFFF)
  reservoirStatus.texture.draw(text)
  text.destroy()
}

function RemoveReservoirStatus(uuid) {
  if (reservoirStatus.data.uuid === uuid || uuid === 'all') {
    if (reservoirStatus.texture) reservoirStatus.texture.destroy();
    reservoirStatus.texture = null;
  }
}

function UpdateReservoirStatus(uuid, count, offsetY = 20) {
  if (reservoirStatus.data.uuid === uuid && reservoirStatus.texture) {
    RemoveReservoirStatus(uuid)
    reservoirStatus.data.count = count
    CreateReservoirStatus(uuid, reservoirStatus.data.type, reservoirStatus.data.name, reservoirStatus.data.parentSprite, count, offsetY)
  }
}

export {CreateReservoirStatus, RemoveReservoirStatus, UpdateReservoirStatus, reservoirStatus}
